import React, { useEffect, useState } from "react";

import ButtonLoading from "../../../components/buttonloading.component";
import { LocalStorageKeys } from "../../../helpers/enum.helpers";
import { JuvoService } from '../../../services/juvo'
import ServiceHelper from '../../../services/serviceHelper'
import { message } from 'antd';
import { Link } from "react-router-dom";

export default function Recovery() {

  const [user, setUser] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const recoveryPassword = () => {
    setLoading(true);
    const userData = { email: user, change: true }
    JuvoService.juvoUserbyEmail(userData,
      ({ juvoUserbyEmail }) => {

        if(juvoUserbyEmail)
        {
            setIsError(false);
            setLoading(false);
            message.success('Has been recovery your password, please review your inbox!')
            window.location.href = "/login";
            
        }
        else
        {
            setErrorMessage('Ops, please review your email');
            setIsError(true);
            setLoading(false);
        }

      },
      (error) => {
        const message = ServiceHelper.getErrorMessageFromGraphql(error)
        setErrorMessage(message || 'Ops an error has ocurred');
        setIsError(true);
        setLoading(false);
      });
  };

  const handleChangeUser = (e) => {
    const email = e.target.value;
    setUser(email);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      recoveryPassword();
    }
  };

  useEffect(() => {
    if (LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN !== null) {
      localStorage.removeItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
      localStorage.removeItem(LocalStorageKeys.KEY_SAVE_USER_DATA);
    }
  }, []);

  return (
    <div onSubmit={(e) => { e.preventDefault(); }}>
      <div className="imageLogin">

      </div>
      <h3>Recovery Password</h3>
      <div className="form-group">
        <label>Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          onKeyDown={keyPress}
          onChange={handleChangeUser}
          value={user}
        />
      </div>

      <div className="form-group text-center">
        {isError && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
      <ButtonLoading
        loading={loading}
        onClick={recoveryPassword}
      >
        Send
      </ButtonLoading>
      <div className="form-group text-center">
        <Link type="link" to="/login" style={{ textAlign: 'center', fontWeight: "bolder" }} success>
          Return to Login
        </Link>
      </div>
    </div>
  );
}