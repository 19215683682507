import { Avatar, Table, Tag } from "antd";
import {
  MobileOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import "antd/dist/antd.css";
import moment from "moment";
import { Button, ButtonGroup } from "@material-ui/core";
import { useEffect } from "react";
import Ohne from "../../assets/juvo_logo.png";
import Juvo from "../../assets/Juvo.svg";

export function DeviceTable({
  onClick,
  isLoading,
  dataList,
  isSearch,
  changePagination,
  totalDevices,
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    total: totalDevices,
  });

  useEffect(() => {
    setPagination({
      current: pagination.current,
      total: totalDevices,
    });
  }, [dataList]);

  useEffect(() => {
    if (isSearch) {
      setPagination({
        current: 1,
        total: totalDevices,
        pageSize: totalDevices,
      });
    } else {
      setPagination({
        current: 1,
        total: totalDevices,
      });
      const newPagination = {
        start: pagination.pageSize * (pagination.current - 1),
        limit: pagination.pageSize,
      };
      changePagination(newPagination);
    }
  }, [totalDevices]);

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (item, index) => {
        if (index.idBrand === "122")
          return (
            <Avatar
              onClick={() => { onClick && onClick(item.item, item.index); }}
              style={{ cursor: "pointer" }}
              size={30}
              icon={<img
                title="Device Detail"
                 src={Ohne} />}
            >
              {index.idBrand}
            </Avatar>
          );
        else if (index.idBrand === "123")
          return (
            <Avatar
            onClick={() => { onClick && onClick(item.item, item.index); }}
            style={{ cursor: "pointer" }}
            size={30} 
            icon={<img src={Juvo} 
            title="Device Detail"
            />}>
              {index.idBrand}
            </Avatar>
          );
        else return <p>Unknown</p>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
      render: (item, index) => {
        return (
          <>
            {item !== null ? (
              <p>
                {item} <MobileOutlined />{" "}
              </p>
            ) : index.customerPhoneNumber !== null &&
              index.customer_status === true ? (
              <p>
                {index.customerPhoneNumber} <UserOutlined />{" "}
              </p>
            ) : (
              <p>None</p>
            )}
          </>
        );
      },
    },
    {
      title: "Client ID",
      dataIndex: "gsfId",
      key: "gsfId",
      align: "center",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      align: "center",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (status) => (
        <>
          {status && (
            <Tag
              color={status?.toUpperCase() === "LOCK" ? "red" : "green"}
              key={status}
            >
              {`${status?.toUpperCase()}ED`}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Permissions",
      key: "hasSetupPermissions",
      dataIndex: "hasSetupPermissions",
      align: "center",
      render: (hasSetupPermissions, hasDeviceAdminPermission) => (
        <>
          {
            <Tag
              color={
                hasSetupPermissions === false ||
                hasDeviceAdminPermission === false
                  ? "red"
                  : "green"
              }
              key={hasSetupPermissions}
            >
              {hasSetupPermissions === false ||
              hasDeviceAdminPermission === false
                ? "NO"
                : "YES"}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "To call",
      key: "waitingResponse",
      dataIndex: "waitingResponse",
      align: "center",
      render: (waitingResponse) => (
        <>
          {
            <Tag
              color={waitingResponse == null ? "red" : "green"}
              key={waitingResponse}
            >
              {waitingResponse == null ? "NO" : "YES"}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
    },
  ];

  const data = dataList?.map((item, index) => {
    return {
      ...item,
      key: item?.id,
      action: { item, index },
      phoneNumber: item?.phoneNumber || null,
      gsfId: item?.gsfId || "None",
      brand: item?.brand || "None",
      model: item?.model || "None",
      status: item?.status || "Unlock",
      created_at: item?.created_at
        ? moment(new Date(parseFloat(item?.created_at))).format("M/D/Y hh:mma")
        : "None",
      updated_at: item?.updated_at
        ? moment(new Date(parseFloat(item?.updated_at))).format("M/D/Y hh:mma")
        : "None",
      customerPhoneNumber: item?.customerPhoneNumber || null,
      customer_status: item?.CustomerStatus || false,
      idBrand: item?.idBrand || null,
    };
  });

  const handleTableChange = (p) => {
    setPagination({ current: p.current, total: pagination.total });
    const newPagination = {
      start: p.pageSize * (p.current - 1),
      limit: p.pageSize,
    };
    changePagination(newPagination);
  };

  return (
    <Table
      loading={isLoading || false}
      dataSource={data}
      columns={columns}
      pagination={pagination}
      scroll={{ y: "calc(100% - 450px)", x: "1080px" }}
      onChange={handleTableChange}
    />
  );
}
