import { Table, message, Space, Tag } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined, LikeOutlined, SecurityScanOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import moment from "moment";
import { Button, ButtonGroup } from "@material-ui/core";
import { JuvoService } from "../../services/juvo/juvo.service";
import { Excel } from 'antd-table-export'
import { LocalStorageKeys } from '../../helpers/enum.helpers';
import {UserModalEdit} from "./edit/user.edit.component";
import {ResetModal} from "./edit/reset.password.component";
import ServiceHelper from '../../services/serviceHelper'

export default function UsersTable({
  onClick,
  onClickEdit,
  isLoading,
  dataList,
  totalDevices,
  setPagination,
  refreshData,
  possibleDevices1
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [pass, setPass] =  useState([]);
  const [customerId, setCustomerId] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [possibleDevices, setPossibleDevices] = useState([]);
  const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
  const roleid = user?.roleid; //nuevo
  var rol = true;
  var des = "";
  if(roleid===3)
  rol=false;

  if(rol)
  {
    window.location.href = "/dashboard";
  }

  const refreshDataRole = (value) => {

    JuvoService.ListRole(({ listRole }) => {
        setPossibleDevices(listRole);
  
      },
      (error) => {
        setPossibleDevices(error.listRole);
      }
    );
  };

  useEffect(() => {
    const onPageLoad = () => {
      refreshDataRole(0);
    };
     // Check if the page has already loaded
     if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  window.onload = function(e){ 
    refreshDataRole(0);
  }  

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index === 4) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: ({ item, index }) => {
        return (
          <ButtonGroup
            variant="outlined"
            color="default"
            size="small"
            className="btn_action_device"
          >
            <Button 
              hidden={rol}
              title = "Reset password"
              onClick={() => resetPassword(item, index)}
            >
              <SecurityScanOutlined />
            </Button>
            <Button title={item.active === "true" ? "Deactivated":"Activated"}
            onClick={() => disabledUser(item.id, item.active)}
            className={item.active === "true" ? "btn  btn-sm btn-danger": "btn  btn-sm btn-suceess"}
            style={{ title:item.active === "true" ? "Deactivated":"Activated", color: item.active === "true" ? "red":"green", alignItems: "center", display: "flex" }}
          >
            {item.active ==="true" ? <DeleteOutlined />:<LikeOutlined />}
          </Button>
          <Button
              onClick={() => {
                
                possibleDevices.map(d => {
                  if(item.roleid === d.id)
                  {
                   des = d.description;
                  
                }
                }
                )
                item.rolS = des;
                setCustomerId(item)
                setIsCustomerModalVisible(true)
                onClickEdit && onClickEdit(item, index);
              }}
            className="btn  btn-sm btn-suceess"
            title= "Edit"
            style={{ color: "green", alignItems: "center", display: "flex" }}
          >
            
            <EditOutlined />
          </Button>
          </ButtonGroup>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "roleid",
      key: "roleid",
      align: "center",
      render: (roleid) => (
        <>
        {
        possibleDevices.map(d => {
          if(roleid === d.id)
          {
           des = d.description;
          
        }
        }
        )
      }
        <label>
          {des}
        </label>
        </>
      )
    },
    {
        title: "Status",
        dataIndex: "active",
        key: "active",
        align: "center",
        filters: [
          {
            text: 'Active',
            value: 'true',
          },
          {
            text: 'Inactive',
            value: 'false',
          },
        ],
        onFilter: (value, record) => record.active.indexOf(value) === 0,
        render: (active) => (
            <>
              {
                <Tag
                  color={active == "false" ? "red" : "green"}
                  key={active}
                >
                  {active === "false" ? "Inactive" : "Active"}
                </Tag>
              }
            </>
          ),
      },    
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",  
    },
  ];

  const data = dataList.users?.map((item, index) => {
    return {
      ...item,
      key: item?.id,
      action: { item, index },
      email: item?.email || "None",
      fullname: item?.fullname || "None",
      active: item?.active || "Active",
      created_at: item?.created_at
        ? moment(new Date(parseFloat(item?.created_at))).format(
            "M/D/Y hh:mma"
          )
        : "None",
    };
  });

  const resetPassword = (user, index) => {
    setLoading(true);
 
    const userData = { email: user.email, change: true }
    JuvoService.juvoUserbyEmail(userData,
      ({ juvoUserbyEmail }) => {

        if(juvoUserbyEmail)
        {
            setLoading(false);
            message.success('Has been recovery your password!')
            setIsResetModalVisible(true)
            setPass(juvoUserbyEmail)
        }
        else
        {
            setLoading(false);
            const message = ServiceHelper.getErrorMessageFromGraphql(juvoUserbyEmail)
            message.error(message || 'Ops an error has ocurred')

        }

      },
      (error) => {
        setLoading(false);
        const message = ServiceHelper.getErrorMessageFromGraphql(error)
        message.error(message || 'Ops an error has ocurred')
      });

  };
  const disabledUser = (id, active) => {
    setLoading(true);
    var status = "false";
    var msg = "deactivated";
    if(active === "false")
    {
      status = "true";
      msg = "activated"
    }
    else
    {
      status = "false";
      msg = "deactivated"      
    }
    refreshDataRole(0);
    JuvoService.updateJuvoUser(
      id,
      {
        active: status,
      },
      () => {

        message.success("The user has been " + msg + " correctly");
        refreshData();
        setLoading(false);
      },
      (e) => {
        message.error("Could not " + msg + " user, please try again");
        setLoading(false);
      }
    );
  };


  
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    const newPagination = {
      start: pagination.pageSize * (pagination.current - 1),
      limit: pagination.pageSize,
    };
    setPagination(newPagination);
  };

  const pagination = {
    current: currentPage,
    total: totalDevices,
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  return (
    <div>
                            <div>
            <Button
            hidden = "hidden"
              style={{
                marginBottom: 20,
                visible: "hidden"
              }}
              onClick={() => {
                const excel = new Excel();
                excel
                  .addSheet('test')
                  .addColumns(columns)
                  .addDataSource(data)
                  .saveAs('users.xlsx');
              }}
            >
              Export
            </Button>
          </div> 
          {isResetModalVisible &&
        <ResetModal
        visible={isResetModalVisible}
        password = {pass}
        onClose={() => setIsResetModalVisible(false)}
        />
      }
          {isCustomerModalVisible &&
        <UserModalEdit
        visible={isCustomerModalVisible}
        refreshData = {refreshData}
        onClickEdit = {onClickEdit}
        CustomerId ={customerId}
        rolS = {des}
        onClose={() => setIsCustomerModalVisible(false)}
        />
      }
      <Table
        loading={isLoading || false}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        scroll={{ x: true }}
        onChange={handleTableChange}
        //rowSelection={rowSelection} //Seleccionar para cambiar estados.
      />
    </div>
  );

}
