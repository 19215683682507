import { Col, Input, Modal, Row, Form, Button, message,AutoComplete, Select  } from "antd";
import React from "react";
import { JuvoService } from "../../../services/juvo/juvo.service";

export default function UserModal({ visible, onClose, refreshData }) {
  const {  useState  } = React;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [possibleDevices, setPossibleDevices] = useState([]);
  const [description, setDeviceId] = useState("");


  const { Option } = Select;

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  
  const onSearch = (value) => {
    console.log('search:', value);
  };

  const start = () => {
    setLoading(true); // ajax request after empty completing
    
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const refreshDataRole = (value) => {
    setDeviceId(value);
    JuvoService.ListRole(({ listRole }) => {
        setPossibleDevices(listRole);
  
      },
      (error) => {
        setPossibleDevices(error.listRole);
      }
    );
  };

  const addUser = (e) => {

    possibleDevices.map(d => {
      if(e.roleid === d.description)
      e.roleid = d.id;
    })

    JuvoService.createJuvoUser({ fullname: e.fullname, email: e.email, password: e.password, roleid: e.roleid },
      () => {
        message.success("The customer has been created successfully");
        onClose();
        form.resetFields();
        refreshData();
      },
      () => {
        message.error("Ops. An error has ocurred!");
      }
    );
  };

  const handleClose = () => {
    onClose();
    visible=false;
    form.resetFields();
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
     <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Modal
        visible={visible}
        title="New User"
        footer={null}
        onCancel={onClose}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addUser}
          onFinishFailed={() => message.error("Ops. An error has ocurred!")}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Fullname"
            name="fullname"
            rules={[{ required: true, message: "Please input your fullname!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
            ]}
          >
               <Input maxLength={20} />

          </Form.Item>
          <Form.Item
            label="Role"
            name="roleid"
            rules={[
              { required: true, message: "Please select your role!" },
            ]}
          >

               <AutoComplete
          style={{ width: '100%', marginRight: '10px' }}
          onChange={(e) => refreshDataRole(e)}
          value={description}
          options={
            possibleDevices.map(device => { return { id:device.id, value: device.description, label:device.description, key:device.id } })
          }
          placeholder="Input rol"
          filterOption={false}
        />
          </Form.Item>
          <Row justify="end">
            <Col style={{ marginInline: "5px" }}>
              <Button className="btn btn-sm btn-light" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col style={{ marginInline: "5px" }}>
              <Button
                type="primary"
                className="btn btn-sm btn-primary"
                htmlType="submit"
              >
                Add User
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}