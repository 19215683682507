import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LocalStorageKeys } from "../helpers/enum.helpers";

const PrivateRoute = (rest) => {
  const token = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
  return (
    <Route
      render={({ location }) =>
        token ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
