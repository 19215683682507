import React, { useEffect, useState } from "react";
import AlertWithoutFooter from "../../components/alertwithoutfooter.component";
import { LocalStorageKeys } from "../../helpers/enum.helpers";
import { message, Tag, Table, List, Avatar, Tabs, Space, Row, Button } from "antd";
import { JuvoService } from "../../services/juvo/juvo.service";
import ServiceHelper from "../../services/serviceHelper";
import {
  MessageOutlined,
  LikeOutlined,
  InfoCircleOutlined,
  DislikeOutlined,
  PhoneOutlined,
  AndroidOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import moment from "moment";
import Logo from "../../assets/phone.png";
import Juvo from "../../assets/juvo_logo.png";
import TextArea from "antd/lib/input/TextArea";
import CustomerDevice from "./add/customerdevice.component";

const { TabPane } = Tabs;

export function CustomerDetail({ onClose, customerId, fullname }) {
  const [customerWasUpdated, setCustomerWasUpdated] = useState(false);
  const [userId, setUserId] = useState();
  const [CustomerId, setCustomerId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customeDetails, setDetails] = useState({
    id: undefined,
    fullname: undefined,
    phonenumber: undefined,
  });
  const handleOnClose = () => {
    onClose && onClose({ isRestart: customerWasUpdated, customerData });
  };

  useEffect(() => {
    setIsLoading(true);
    setCustomerId(customerId)
    localStorage.setItem(LocalStorageKeys.KEY_SAVE_USER_CUSTOMER, customerId);

    const { id } = JSON.parse(
      localStorage.getItem(LocalStorageKeys.KEY_SAVE_USER_DATA)
    );

    if (id === null)
      message.error(
        "Sorry, an unexpected error ocurred. Contact technical support"
      );
    else setUserId(id);

    setIsLoading(true);
    JuvoService.devicebyOwner(
      customerId,
      ({ devicebyOwner }) => {
        setIsLoading(false);
        setIsError(false);
        
        setCustomerData(devicebyOwner);
      },
      (e) => {
        setIsLoading(true);
        setIsError(true);
        message.error(
          ServiceHelper.getErrorMessageFromGraphql(e) ||
            "Ops. An error has ocurred!"
        );
      }
    );
  }, []);

  var data;

  if (customerData) {
    

    data = customerData?.map((item, index) => {
      return {
        ...item,
        key: item?.id,
        action: { item, index },
        phoneNumber: item?.phoneNumber || "None",
        hasSetupPermissions: item?.hasSetupPermissions,
        gsfId: item?.gsfId || "None",
        brand: item?.brand || "None",
        model: item?.model || "None",
        status: item?.status || "Unlock",
        created_at: item?.created_at
          ? moment(new Date(parseFloat(item?.created_at))).format(
              "M/D/Y hh:mma"
            )
          : "None",
        updated_at: item?.updated_at
          ? moment(new Date(parseFloat(item?.updated_at))).format(
              "M/D/Y hh:mma"
            )
          : "None",
      };
    });
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const refreshData = () => {
    setIsLoading(true);
    JuvoService.devicebyOwner(
      customerId,
      ({ devicebyOwner }) => {
        setIsLoading(false);
        setIsError(false);
        
        setCustomerData(devicebyOwner);
      },
      (e) => {
        setIsLoading(true);
        setIsError(true);
        message.error(
          ServiceHelper.getErrorMessageFromGraphql(e) ||
            "Ops. An error has ocurred!"
        );
      }
    ); 
  }

  const UnassignDevice = (deviceId) => {

    JuvoService.updateJuvoDevice(
      { id: deviceId, input: { customerId: null } },
      () => {
        message.success("Has been unassigned successfully");
        refreshData();
      },
      (error) => {
        message.error("Could not assign, contact technical support");
      }
    );
  };


  return (
    <div>
      <AlertWithoutFooter title={"Customer Information" + (fullname ? ': ' + fullname : '')} onClose={handleOnClose}>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <InfoCircleOutlined />
                Assigned Devices
              </span>
            }
            key="1"
          >
            <List
              itemLayout="vertical"
              size="small"
              pagination={{
                onChange: (page) => {
                },
                pageSize: 3,
              }}
              dataSource={data}
              header={
                <div>
                  <b>Devices by Customer</b>
                </div>
              }
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  actions={[
                    <IconText
                      icon={PhoneOutlined}
                      text={item.waitingResponse ? "YES" : "NO"}
                      key="list-vertical-star-o"
                    />,
                    <IconText
                      icon={
                        item.status?.toUpperCase() === "LOCK"
                          ? DislikeOutlined
                          : LikeOutlined
                      }
                      text={`${item.status?.toUpperCase()}ED`}
                      key="list-vertical-like-o"
                    />,
                    <IconText
                      icon={
                        item.hasSetupPermissions
                          ? LikeOutlined
                          : DislikeOutlined
                      }
                      text={
                        item.hasSetupPermissions
                          ? "Permissions: ON"
                          : "Permissions: OFF"
                      }
                      key="list-vertical-message"
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={Logo} />}
                    title={<a href={item.href}>{"Marca: " + item.brand}</a>}
                    description={item.model + ". Cliente ID: " + item.gsfId}
                  />
                  {item.content}
                  <Button
            onClick={() => UnassignDevice(item.id)}
            className="btn  btn-sm btn-danger"
            style={{ color: "red", alignItems: "center", display: "flex" }}
          >
            Unassign device
            <DeleteOutlined />
          </Button>
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <AndroidOutlined />
                Available Devices
              </span>
            }
            key="2"
          >
            <CustomerDevice refreshData={refreshData} customerId={customerId}/>

          </TabPane>
        </Tabs>
      </AlertWithoutFooter>
    </div>
  );
}