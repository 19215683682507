import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";

import { LocalStorageKeys } from "../../helpers";
import { JuvoService } from "../../services/juvo";
import { mediumPassword } from "../../utils/validatePassword";

export default function ChangePasswordForm() {
  const [isStrongPassword, setStrongPassword] = useState(false)
  const [isConfirmPassword, setConfirmPassword] = useState(false)
  const [password, setPassword] = useState('aa')
  const [oldPassword, setOldPassword] = useState('')
  
  const [isLengthValid, setIsLengthValid] = useState(false)
  const [haveUppercaseLetter, setHaveUppercaseLetter] = useState(false)
  const [haveLowercaseLetter, setHaveLowercaseLetter] = useState(false)
  const [haveNumber, setHaveNumber] = useState(false)

  const inputPasswordEvent = e => {
    setPassword(e.target.value)
    
    const upperCase = new RegExp("^(?=.*?[A-Z]).{0,}$")
    const lowerCase = new RegExp("^(?=.*?[a-z]).{0,}$")
    const number = new RegExp("^(?=.*?[0-9]).{0,}$")
    const length = new RegExp("^.{7,}$")

    setStrongPassword(mediumPassword.test(e.target.value))
    setHaveUppercaseLetter(upperCase.test(e.target.value))
    setHaveLowercaseLetter(lowerCase.test(e.target.value))
    setHaveNumber(number.test(password))
    setIsLengthValid(length.test(password))
  }

  const onFinish = () => {
    const userLocal = JSON.parse(
      localStorage.getItem(LocalStorageKeys.KEY_SAVE_USER_DATA)
    );
    const { id, fullname, email } = userLocal;

    if (!isConfirmPassword) {
      message.warning(
        "The new password does not match the password confirmation, please try again."
      );
      return;
    } else if (!isStrongPassword) {
      message.warning("Enter a strong password, please try again.");
      return;
    }
    JuvoService.signIn(
      { email: email, password: oldPassword },
      () => {
        JuvoService.updateJuvoUser(
          id,
          {
            email: email,
            fullname: fullname,
            password: password,
            active: "true",
          },
          () => {

            message.success("The password has been updated correctly");
            window.location.href = "/account/general";
          },
          (e) => {
            message.error("Could not update password, please try again");
          }
        );
      },

      (error) => {
        message.error("The old password is incorrect, please try again.");
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 7 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Old Password:"
        name="oldPassword"
        rules={[{ required: true, message: "Please input your old password!" }]}
      >
        <Input.Password 
          onChange={e => setOldPassword(e.target.value)}
        />
      </Form.Item>

      <Form.Item
        label="New Password:"
        name="newPassword"
        rules={[{ required: true, message: "Please input your new password!" }]}
      >
        <Input.Password 
          style={{ borderColor: isStrongPassword ? "lightgreen" : "red" }} 
          onChange={inputPasswordEvent}
          maxLength={15}
          />
      </Form.Item>

      <Form.Item
        label="Confirm New Password:"
        name="confirmPassword"
        maxLength={15}
        rules={[
          { required: true, message: "Please input your new password again!" },
        ]}
      >
        <Input.Password 
          style={{ borderColor: isConfirmPassword ? "lightgreen" : "red" }}
          onChange={e => setConfirmPassword(e.target.value === password)}
          maxLength={15}
        />
      </Form.Item>

     {/* cambiar 
       <Form.Item style={{justifyContent:"center", marginRight:"20%"}}>
        <Checkbox
          enabled={false}
          checked={isLengthValid}
        >
          Minimum eight characters
        </Checkbox>
      </Form.Item>
      <Form.Item style={{justifyContent:"center", marginRight:"20%"}}>
        <Checkbox
          enabled={false}
          checked={haveLowercaseLetter}
        >
          Contains a lowercase letter
        </Checkbox>
      </Form.Item>
      <Form.Item style={{justifyContent:"center", marginRight:"20%"}}>
        <Checkbox
          enabled={false}
          checked={haveUppercaseLetter}
        >
          Contains a capital letter
        </Checkbox>
      </Form.Item>
      <Form.Item style={{justifyContent:"center", marginRight:"20%"}}>
        <Checkbox
          enabled={false}
          checked={haveNumber}
        >
          Contains a number
        </Checkbox>
      </Form.Item>
      <Form.Item style={{justifyContent:"center", marginRight:"20%"}}>
        <Checkbox
          enabled={false}
          checked={isConfirmPassword}
        >
          The new passwords match
        </Checkbox>
      </Form.Item>    
     */}

      <Form.Item wrapperCol={{ offset: 10, span: 20 }}>
        <Button 
          type="primary" 
          htmlType="submit" 
          disabled={!(isConfirmPassword && haveLowercaseLetter && haveUppercaseLetter && haveNumber && isLengthValid)}
          >
          Change Password
        </Button>
      </Form.Item>
      <Form.Item style={{justifyContent:"center", marginRight:"20%", color:"Green"}} wrapperCol={{ offset: 8, span: 20 }}>
        <p>
        *Minimum 8 digits, which has a capital letter and an alphanumeric.
        </p>
      </Form.Item>
      
    </Form>
  );
}
