import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    widows: '100%',
    height: '40vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function Loading() {
  const classes = useStyles();
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  return (
    <div className={classes.root}>
      <Spin
          indicator={antIcon}
      />
    </div>
  )
}