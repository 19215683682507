import { Col, Input, Modal, Row, Form, Button, message } from "antd";
import React from "react";
import { JuvoService } from "../../../services/juvo/juvo.service";

export function CustomerModalEdit({ visible, onClose, refreshData, CustomerId,onClickEdit }) {


  const [form] = Form.useForm();

  const editCustomer = (e) => {

    JuvoService.updateJuvoCustomer(
      { id: CustomerId.id, input: { fullname: e.fullname, phonenumber: e.phonenumber } },
      () => {
        message.success("Has been updated successfully");
        refreshData();
        onClose();
        form.resetFields();
      },
      (error) => {
        message.error("Could not assign, contact technical support");
      }
    );

  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <div>
      <Modal
        visible={visible}
        title="Edit Customer "
        footer={null}
        onCancel={onClose}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={editCustomer}
          onFinishFailed={() => message.error("Ops. An error has ocurred!")}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Fullname"
            name="fullname"
            initialValue={CustomerId.fullname}
            rules={[{ required: true, message: "Please input your fullname!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phonenumber"
            initialValue = {CustomerId.phonenumber}
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input maxLength={20} />
          </Form.Item>
          <Row justify="end">
            <Col style={{ marginInline: "5px" }}>
              <Button className="btn btn-sm btn-light" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col style={{ marginInline: "5px" }}>
              <Button
                type="primary"
                className="btn btn-sm btn-primary"
                htmlType="submit"
              >
                Edit Customer
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
