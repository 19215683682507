import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Layout, Button } from "antd";
import { message } from "antd";
import UsersTable from "./users.table.component";
import UserModal from "./add/new.user.component";


import { Link } from "react-router-dom";
import { LocalStorageKeys } from "../../helpers";
import { JuvoService } from "../../services/juvo";

const { Content, Sider } = Layout;

export default function Users() {

    const {  useState  } = React;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [possibleDevices, setPossibleDevices] = useState([]);
    const [description, setDeviceId] = useState("");
  
    const start = () => {
      setLoading(true); // ajax request after empty completing
  
      setTimeout(() => {
        setSelectedRowKeys([]);
        setLoading(false);
      }, 1000);
    };
    const [isLoading, setIsLoading] = useState(false);
    const [UsersList, setUsersList] = useState([]);
    const [pagination, setPagination] = useState({ start: 0, limit: 10 });
    const [totalDevices, setTotalDevices] = useState(0);
    const [searchText, setSearchText] = useState();
    const [isUsersDetailVisible, setIsUsersDetailVisible] = useState(false);
    const [userDetail, setUserDetail] = useState({});
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);


    const clearSearch = () => {
        // Get device list
        setIsLoading(true);
        setUsersList([]);
        setSearchText("");
        JuvoService.ListUsers(pagination,({ juvoUsers }) => {
            setUsersList(juvoUsers);
            setTotalDevices(juvoUsers.totalCount);
            setIsLoading(false);
          },
          (e) => {
            setIsLoading(false);
            message.error("Ops. An error has ocurred!");
          }
        );
      }

      const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
      };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
      const hasSelected = selectedRowKeys.length > 0;

      const onClickRow = (data, index) => {
        setIsUsersDetailVisible(true);
        setUserDetail(data);
      };
      const refreshDataRole = (value) => {
        setDeviceId(value);
        JuvoService.ListRole(({ listRole }) => {
            setPossibleDevices(listRole);
      
          },
          (error) => {
            setPossibleDevices(error.listRole);
          }
        );
      };

      

      window.onload = function(e){ 
        refreshDataRole(0);
      }  

    const searchCustomer = () => {
        setIsLoading(true)
        setUsersList([]);
        refreshDataRole(0);
        JuvoService.findUsers(searchText ,(result) => {
          setUsersList({ users: result.JuvoUserBySearch.users})
          setTotalDevices(result.length)
          setIsLoading(false)
        }
        ,
          (e) => {
            setIsLoading(false);
            message.error("Ops. An error has ocurred!");
          })
      }
    useEffect(() => {
        // Get device list
        setIsLoading(true);
        setUsersList([]);
        JuvoService.ListUsers(pagination,({ juvoUsers }) => {
            setUsersList(juvoUsers);
            setTotalDevices(juvoUsers.totalCount);
            setIsLoading(false);
          },
          (e) => {
            setIsLoading(false);
            message.error("Ops. An error has ocurred!");
          }
        );
      }, [pagination]);

      const refreshData = () => {
        setIsLoading(true);
        setUsersList([]);
        JuvoService.ListUsers(
          pagination,
          ({ juvoUsers }) => {
            setTotalDevices(juvoUsers.totalCount);
            setUsersList(juvoUsers);
            setIsLoading(false);
          },
          (e) => {
            setIsLoading(false);
            message.error("Ops. An error has ocurred!");
          }
        );
      }

      useEffect(() => {
        setIsLoading(true);
        setUsersList([]);
        JuvoService.ListUsers(pagination,({ juvoUsers }) => {
            //setTotalDevices(juvoUsers.totalCount);
            setUsersList(juvoUsers);
            setTotalDevices(juvoUsers.totalCount);
            setIsLoading(false);
          },
          (e) => {
            setIsLoading(false);
            message.error("Ops. An error has ocurred!");
          }
        );
      }, [pagination]);

      return (
        <div className="site-layout-content" style={{ minHeight: "600px" }}>
      <UserModal
        visible={isUserModalVisible}
        refreshData = {refreshData}
        onClose={() => setIsUserModalVisible(false)}
      />
          <div className="header-device">
            <div className="form-search-gsfid">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Find by name"
                value={searchText}
                onChange={(e) => {setSearchText(e.target.value)}}
              />
              <button type="button" className="btn btn-primary btn-block btn-sm" onClick={searchCustomer}>
                Search
              </button>
              <button type="button" className="btn btn-secondary btn-block btn-sm" onClick={clearSearch}>
                Clear
              </button>
              <button
                type="button"
                className="btn btn-info btn-block btn-sm"
                title="Add User"
                onClick={() => setIsUserModalVisible(true)}
              >
                Add
              </button>
            </div>
          </div>

          <div className="seperator-80" />
          <div className="border-device-table" style={{ width: "100%" }}>
            <UsersTable
              isLoading={isLoading}
              onClick={onClickRow}
              dataList={UsersList}
              refreshData = {refreshData}
              totalDevices={totalDevices}
              possibleDevices1={possibleDevices}
              rowSelection={rowSelection}
              setPagination={(pagination) => {
                setPagination(pagination);
              }}
            />
          </div>
        </div>
      );

}