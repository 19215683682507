import { Table, message } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "antd/dist/antd.css";
import moment from "moment";
import { Button, ButtonGroup } from "@material-ui/core";
import { JuvoService } from "../../services/juvo/juvo.service";
import {CustomerModalEdit} from "./edit/customermodalEdit.component";

export default function CustomerTable({
  onClick,
  onClickEdit,
  isLoading,
  dataList,
  totalDevices,
  setPagination,
  refreshData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [customerId, setCustomerId] = useState();
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: ({ item, index }) => {
        return (
          <ButtonGroup
            variant="outlined"
            color="default"
            size="small"
            className="btn_action_device"
          >
            <Button
              onClick={() => {
                onClick && onClick(item, index);
              }}
            >
              <EyeOutlined />
            </Button>
            <Button
            onClick={() => disabledCustomer(item.id)}
            className="btn  btn-sm btn-danger"
            style={{ color: "red", alignItems: "center", display: "flex" }}
          >
            
            <DeleteOutlined />
          </Button>
          <Button
              onClick={() => {
                setCustomerId(item)
                setIsCustomerModalVisible(true)
                onClickEdit && onClickEdit(item, index);
              }}
            className="btn  btn-sm btn-suceess"
            title= "Edit"
            style={{ color: "green", alignItems: "center", display: "flex" }}
          >
            
            <EditOutlined />
          </Button>
          </ButtonGroup>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",  
    },
  ];
  
  const data = dataList.customers?.map((item, index) => {
    return {
      ...item,
      key: item?.id,
      action: { item, index },
      phoneNumber: item?.phonenumber || "None",
      fullname: item?.fullname || "None",
      created_at: item?.created_at
        ? moment(new Date(parseFloat(item?.created_at))).format(
            "M/D/Y hh:mma"
          )
        : "None",
    };
  });
  const disabledCustomer = (deviceId) => {
    JuvoService.updateJuvoCustomer(
      { id: deviceId, input: { statuscus: false } },
      () => {
        message.success("Has been disabled successfully");
        refreshData();
      },
      (error) => {
        message.error("Could not assign, contact technical support");
      }
    );
  };


  
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    const newPagination = {
      start: pagination.pageSize * (pagination.current - 1),
      limit: pagination.pageSize,
    };
    setPagination(newPagination);
  };

  const pagination = {
    current: currentPage,
    total: totalDevices,
  };

  return (
    <div>
      {isCustomerModalVisible &&
        <CustomerModalEdit
        visible={isCustomerModalVisible}
        refreshData = {refreshData}
        onClickEdit = {onClickEdit}
        CustomerId ={customerId}
        onClose={() => setIsCustomerModalVisible(false)}
        />
      }
      <Table
        loading={isLoading || false}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        scroll={{ x: true }}
        onChange={handleTableChange}
        
      />
    </div>
  );
}
