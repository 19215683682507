import './setupEnv';
import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { MainLogin } from './web/login/mainlogin.component';
import Text404 from './components/text404';
import Interface from './components/interface.component';
import { MainRecovery } from './web/login/recoverypassword/recoverypassword.component';


export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={MainLogin} />
        <Route exact path="/recoverypassword" component={MainRecovery} />
        <Interface/>
        <Route path="*">
          <Text404 />
        </Route>
      </Switch>
    </Router>
  );
}