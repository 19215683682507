import { DeleteOutlined, ExpandOutlined } from "@ant-design/icons";
import { Col, Collapse, Drawer, Row, Spin, message, Typography, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import { JuvoService } from "../services/juvo";
import { DeviceDetail } from "../web/devices/devicedetail/devicedetail.component";

const { Title } = Typography;
const { Panel } = Collapse;

export default function NotificationPanel({ visible, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [deviceId, setDeviceId] = useState();
  const [visibleDeviceModal, setVisibleDeviceModal] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    getNotifications();
    getEvents();
  }, [visible]);

  const getEvents = () => {
    JuvoService.getEventType(
      null,
      (eventTypes) => {
        setEventTypes(eventTypes);
      },
      (e) => {
        message.error("Ops. An error has ocurred!");
      }
    );
  };

  const getNotifications = () => {
    setIsLoading(true);
    JuvoService.getUserNotifications(
      ({ notifications, totalCount }) => {
        setNotificationCount(totalCount);
        setNotificationList(notifications);
        setIsLoading(false);
      },
      () => {
        message.error("Ops. An error has ocurred!");
        setIsLoading(false);
      }
    );
  };

  const markAllAsSeen = () => {
    JuvoService.markAsSeenAllNotifications(
      () => {
        message.success("Marked as seen");
        getNotifications();
        onClose();
      },
      () => {
        message.error("Ops, an error has occurred");
      }
    );
  };

  const openNotification = (idDevice, notificationId) => {
    setDeviceId(idDevice);
    setVisibleDeviceModal(true);
    onClose();
    markAsSeenNotification(notificationId);
  };

  const markAsSeenNotification = (id) => {
    JuvoService.markAsSeenNotification(
      id,
      () => {
        message.success("Marked as seen");
        getNotifications();
      },
      (e) => {
        message.error("Ops, an error has occurred");
      }
    );
  };

  const closeModal = () => {
    setVisibleDeviceModal(false);
  };

  const eventsNotification = (data) => {
    return (
      <Row gutter={[20, 0]} style={{ marginLeft: "5px" }}>
        <Col>
          <ExpandOutlined
            style={{ fontSize: "14px" }}
            onClick={() => openNotification(data.id_device, data.id)}
            title="Open device detail"
          />
          {/* <Tag icon={<AuditOutlined />} style={{ display: "flex", alignItems: "center", color: "white" }} onClick={() => openNotification(data.id_device, data.id) } className="btn btn-sm btn-primary">Open</Tag> */}
        </Col>
        <Col>
          <DeleteOutlined
            style={{ fontSize: "14px" }}
            onClick={() => markAsSeenNotification(data.id)}
            title="Mark as seen this notification"
          />
          {/* <Tag icon={<CheckCircleOutlined />} className="btn btn-secondary btn-sm" style={{ display: "flex", alignItems: "center" }} onClick={() => markAsSeenNotification(data.id)}> Mark as seen </Tag> */}
        </Col>
      </Row>
    );
  };

  const getNotificationTitle = (event_id) => {
    const eventType = eventTypes.find((e) => e.id === event_id);
    return eventType ? eventType.description : "Other event";
  };

  return (
    <>
      {visibleDeviceModal && (
        <DeviceDetail deviceId={deviceId} onClose={closeModal} />
      )}
      {visible && (
        <Drawer
          title={
            <>
              <Row align="bottom" justify="center">
                <Col>
                  <Title level={2}>Your notifications</Title>
                </Col>
              </Row>
              <Row align="bottom" justify="end">
                <Col>
                  {notificationCount > 0 && (
                    <button
                      className="btn btn-sm btn-link font-smaller"
                      onClick={markAllAsSeen}
                      style={{ fontSize: "12px" }}
                    >
                      Mark all as seen
                    </button>
                  )}
                </Col>
              </Row>
            </>
          }
          placement="right"
          width="auto"
          closable={false}
          onClose={onClose}
          visible={visible}
          style={{ position: "absolute" }}
        >
          <Spin spinning={isLoading}>
            <Collapse expandIconPosition="left" ghost>
              {notificationList.map((data) => (
                <Panel
                  header={getNotificationTitle(data.event_id)}
                  key={data.id}
                  bordered={false}
                  showArrow={true}
                  style={{ marginBottom: "5%" }}
                  extra={eventsNotification(data)}
                >
                  <Row>
                    <Col flex="auto">
                      <p>{'Model: ' + data.model}</p>
                    </Col>
                    <Col>
                      <Tag
                        color={
                          data.status?.toUpperCase() === "LOCK"
                            ? "red"
                            : "green"
                        }
                        key={data.status}
                      >{`${data.status?.toUpperCase()}ED`}</Tag>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex="auto">
                      <p>{"Brand: " + data.brand}</p>
                    </Col>
                    <Col>
                      <Row>
                        <p style={{ marginRight: "5px" }}>Callback Request: </p>
                        <Tag
                          style={{ height: "25px" }}
                          color={data.waitingResponse == null ? "red" : "green"}
                          key={data.waitingResponse}
                        >
                          {data.waitingResponse == null ? "NO" : "YES"}
                        </Tag>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify='end'>
                    <Col>
                      <p>Date of notification: {data?.updated_at
                          ? moment(
                              new Date(parseFloat(data?.updated_at))
                            ).format("M/D/Y hh:mma")
                          : "None"}
                      </p>
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Collapse>
          </Spin>
        </Drawer>
      )}
    </>
  );
}
