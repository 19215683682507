import { LocalStorageKeys } from "../helpers/enum.helpers";

export default class ServiceHelper {
  static checkUnauthorized(error) {
    const { graphQLErrors } = error || {};
    const { firstError } = graphQLErrors || {};
    const { extensions } = firstError || {};
    const { code } = extensions || {};

    if (code === 401 || graphQLErrors?.pop()?.message === "Not Authorised!") {
      localStorage.removeItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
      window.location.href = '/login';
    }
  }

  static getErrorMessageFromGraphql(error) {
    const { graphQLErrors, message: alternativeError } = error || {};
    const { firstError } = graphQLErrors || {};
    const { message } = firstError || {};
    return message || alternativeError;
  }
}