import React from 'react';
import { Modal } from 'antd';
import { makeStyles } from '@material-ui/core/styles';

export const deviceStyle = makeStyles(() => ({
  dialogWidth: {
    width: '80% !important',
    maxWidth: '800px !important',
  },
}));

const AlertWithoutFooter = (props) => {
  const { onClose, title } = props
  const classes = deviceStyle();

  const handleOnClose = () => {
    onClose && onClose({ restart: false });
  };

  return (
    <>
      <Modal 
        className={classes.dialogWidth}
        title={title || "Information"}
        closable={true}
        maskClosable={false}
        footer={null}
        visible
        onCancel={handleOnClose}
      >
        <div>{props.children}</div>
      </Modal>
    </>
  );
};

export default AlertWithoutFooter;