import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Layout, Menu } from "antd";
import {
  ControlOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

import PrivateRoute from "../../components/privateroute";
import ChangePasswordForm from "./changepassword.component";
import { Link } from "react-router-dom";
import GeneralInformationForm from "./generalinformation.component";
import { LocalStorageKeys } from "../../helpers";
import { JuvoService } from "../../services/juvo";

const { Content, Sider } = Layout;



export default function Account() {
  const history = useHistory()
  const [userData, setUserData] = useState({
    id: undefined,
    fullname: undefined,
    email: undefined,
  });

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem(LocalStorageKeys.KEY_SAVE_USER_DATA))
    if(userLocal === null)
      return

    const { id } = userLocal
    setUserData(userLocal)
    // Get User Information
    JuvoService.getJuvoUser(id, ( { juvoUser } ) => {
      setUserData(juvoUser)
    }, (e) => {})
  }, []);

  return (
    <Content style={{ padding: "0 50px" }}>
      <Layout className="site-layout-background" style={{ padding: "24px 0" }}>
        <Sider className="site-layout-background" width={200}>
          <Menu mode="inline" defaultSelectedKeys={history.location.pathname.split('/').pop()} defaultOpenKeys={["sub1"]} style={{ height: "100%" }}>
            <Menu.Item key="general" icon={<ControlOutlined/>}>
              <Link to="/account/general">Profile</Link>
              </Menu.Item>
            <Menu.Item key="security" icon={<SecurityScanOutlined/>}>
            <Link to="/account/security">Change Password</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content 
        style={{ padding: "30px", background:"White", height:"calc(100vh - 250px)", minHeight:"500px", minWidth:"500px"}}
        >
          <PrivateRoute path="/account/general" render={() => (<GeneralInformationForm userData={userData}/>)}/>
          <PrivateRoute path="/account/security" render={() => (<ChangePasswordForm/>)}/>
        </Content>
      </Layout>
    </Content>
  );
}
