import React from "react";
import { Col, DatePicker, Divider, Input, message, Radio, Row } from "antd";
import { useParams } from 'react-router-dom'
import { JuvoService } from "../../services/juvo";
import { useState } from "react";
import { useEffect } from "react";
import { HistoryTable } from "./historytable.component";

const { RangePicker } = DatePicker;

export default function MainHistory() {
  const { filterDefault } = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, limit: 10 });
  const [totalEvents, setTotalEvents] = useState(0);
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState('');
  const [datePicker, setDatePicker] = useState();
  const [eventTypes, setEventTypes] = useState([]);

  const getEvents = () => {
    let fields = filter ? { event_id : filter } : null;

    let dateRange = [];
    if(datePicker){
      const init = new Date(datePicker[0]);
      init.setDate(init.getDate() - 1);
      dateRange = [init, datePicker[1]];
    }

    setIsLoading(true)
    setEventList([])
    JuvoService.getEventHistory({ ...pagination, fields, search, startDate: dateRange[0], endDate: dateRange[1]
    }, ({ events, totalCount }) => {
      setTotalEvents(totalCount)
      setEventList(events)
      setIsLoading(false)
    }, (e) => {
      setIsLoading(false)
      message.error('Ops. An error has ocurred!')
    })
  }

  useEffect(() => {
    JuvoService.getEventType(null, 
      (eventTypes) => {
        setEventTypes(eventTypes)
      }, (e) => {
        message.error('Ops. An error has ocurred!')
      });
  },[])

  useEffect(() => {
    getEvents();
  }, [pagination, filter, search, datePicker]);

  const onChangeFilter = (e) => {
    setFilter(e.target.value)
  }
  
  const changeDateRange = e => {
    setDatePicker(e)
  }

  const clearParams = () => {
    setDatePicker();
    setSearch('');
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Divider orientation="left">
            <h2>Event History</h2>
          </Divider>
        </Col>
      </Row>
      <Row className="site-layout-content" justify='center' align='middle'>
        <Col span={24}>
          <Row align='middle' justify='center'>
            <Col style={{marginInlineEnd: '10px'}}>
              Event type:
            </Col>
            <Col>
              <Radio.Group defaultValue={filter} onChange={onChangeFilter}>
                <Radio.Button value={null}>All</Radio.Button>
                { eventTypes.map(eventType => <Radio.Button style={{marginTop:'10px'}} value={eventType.id}>{eventType.event_name}</Radio.Button>) }
              </Radio.Group>
            </Col>
          </Row>
          <Row align='middle' justify='center' style={{ marginTop:'20px' }}>
            <Col span={6}>
              <Input placeholder="Enter name or gsfId to search" value={search} onChange={e => setSearch(e.target.value)}/>
            </Col>
            <Col offset={1}>
              <RangePicker value={datePicker} onChange={changeDateRange}/>
            </Col>
            <Col offset={1}>
              <button className='btn btn-sm btn-secondary' onClick={clearParams}>Clear</button>
            </Col>
          </Row>
          <Divider/>
          <Row>
            <HistoryTable
              isLoading={isLoading}
              dataList={eventList}
              totalItems={totalEvents}
              changePagination={(pagination) => { setPagination(pagination) }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
