import { Redirect, Route, useHistory } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import React, { useState, useEffect } from 'react';
import { Footer } from 'antd/lib/layout/layout';

import { MainDevice } from '../web/devices/maindevice.component';
import PrivateRoute from './privateroute';
import { VERSION_NAME } from '../helpers/enum.helpers';
import Account from '../web/account/account.component';
import LeftMenuBar from './leftmenubar.component';
import TopMenuBar from './topmenubar.component';
import Dashboard from '../web/dashboard/dashboard.component';
import Customers from '../web/customers/customers.componet'
import  Users from '../web/users/users.component'
import MainHistory from '../web/history/mainhistory.component';
import { JuvoService } from '../services/juvo';
import { LocalStorageKeys } from '../helpers/enum.helpers';
const { Content } = Layout;


export default function Interface() {
  const [darkTheme, setDarkTheme] = useState(false)
  const [path, setPath] = useState([])
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
  const roleid = user?.roleid; //nuevo

  const setNewPath = (path) => {
    const newPath = path.split("/").map(function (section, index){
      if(section.length > 0 && section.length < 15 && index < 10)
        return section[0].toUpperCase() + section.slice(1)
      else
        return null
    })
    setPath(newPath)
  }

  useEffect(() => {
    return history.listen((location) =>setNewPath(location.pathname))
  },[history])

  useEffect(() => {
    setNewPath(history.location.pathname) 
  },[])

  const darkThemeEvent = () => {
    setDarkTheme(!darkTheme)
  };



  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LeftMenuBar darkTheme={darkTheme} setDarkTheme={darkThemeEvent}/>
      <Layout className="site-layout" style={{minWidth:'1024px'}}>
        <TopMenuBar darkTheme={darkTheme}/>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {
              path.map(function (item, index) { return <Breadcrumb.Item key={item + index}>{item}</Breadcrumb.Item>})
            }
          </Breadcrumb>
          <Route exact path="/"><Redirect to="/dashboard" /></Route>
          <Route exact path="/account"><Redirect to="/account/general" /></Route>

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/customers" component={Customers} />
          <PrivateRoute path="/users" component={Users} render={roleid===3}/>
          <PrivateRoute exact path="/devices/:dangerousness/:filterDefault" component={() => <MainDevice/>} />
          <PrivateRoute exact path="/history/:filterDefault" component={() => <MainHistory/>} />
          <PrivateRoute path="/account" component={Account}/> 
          
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Ohne ©2022 Powered by IMOX. 
          <br/>
          {VERSION_NAME}
        </Footer>
      </Layout>
    </Layout>
  );
}
