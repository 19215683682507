import { ConnectionService } from "../connection";
import ServiceHelper from "../serviceHelper";
import { LocalStorageKeys } from "../../helpers";
import { juvoDevice, juvoDeviceBySearch, juvoDevices, juvoUser, sendPushLockUnlock, signInJuvoUser, createJuvoUser, updateJuvoUser, sendPush,generatePin, juvoDeviceStats, juvoDevicesRequestedCall, reportJuvoEvent, sendMultiplePush, juvoNotificationsByUser, markAsSeenNotification, juvoCustomers, juvoDevicesWithCustomers, devicebyOwner, createJuvoCustomer, updateJuvoData, juvoCustomerBySearch, updateJuvoCustomer, juvoUserbyEmail, findCustomers, juvoDashboardsData, juvoDevicesAll, markAsSeenAllJuvoNotification, juvoEventHistory, juvoEventTypes, sendPushToForceUpdateTokenJuvo,sendPushToForceUpdateTokenOhne, juvoUsers,listRole,JuvoUserBySearch} from "../schemas";
import { now } from "moment";


export class JuvoService {
  static getJuvoUser(userId, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoUser,
      variables: {
        id: userId,
      },
    }).then((response) => {
      const { juvoUser } = response?.data;
      res && res({ juvoUser });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
  
  static getDevicesRequestedCall(res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDevicesRequestedCall,
    }).then((response) => {
      const { devices, totalCount } = response?.data?.juvoDevicesRequestedCall;
      res && res({ devices, totalCount });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getDevices(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDevices,
      variables: {
        start: actionData?.start,
        limit: actionData?.limit,
        fields: actionData?.fields
      },
    }).then((response) => {
      const { devices, totalCount } = response?.data?.juvoDevices;
      res && res({ devices, totalCount });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getDevicesWithCustomers(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDevicesWithCustomers,
      variables: {
        start: actionData?.start,
        limit: actionData?.limit,
        fields: actionData?.fields,
        risky: actionData?.risky
      },
    }).then((response) => {
      const { devices, totalCount } = response?.data?.juvoDevicesWithCustomers;
      res && res({ devices, totalCount });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getDevicesBySearch(gsfId, fields, risky, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    
    client.query({
      query: juvoDeviceBySearch,
      variables: {
        fields,
        gsfId,
        start: 0,
        limit: 10,
        risky: risky
      },
    }).then((response) => {
      const { juvoDeviceBySearch } = response?.data;
      res && res({ juvoDeviceBySearch });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static findCustomers(id,fullname, phonenumber, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: findCustomers,
      variables: {
        id,
        phonenumber,
        fullname,
        start: 0,
        limit: 10,
      },
    }).then((response) => {
      const { findCustomers } = response?.data;
      res && res({ findCustomers });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
  static findUsers(fullname, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);


    client.query({
      query: JuvoUserBySearch,
      variables: {
        search:fullname,
        start: 0,
        limit: 10,
      },
    }).then((response) => {

      const { JuvoUserBySearch } = response?.data;
      res && res({ JuvoUserBySearch });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static ListUsers(pagination,res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoUsers,
      variables: {
        start: pagination?.start,
        limit: pagination?.limit,
      },
    }).then((response) => {
      console.log(response?.data.juvoUsers)
      const { juvoUsers } = response?.data;
      res && res({ juvoUsers });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static ListRole(pagination,res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: listRole,
      variables: {
        start: pagination?.start,
        limit: pagination?.limit,
      },
    }).then((response) => {

      const { listRole } = response?.data;
      res && res({ listRole });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }


  static getDeviceById(deviceId, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDevice,
      variables: {
        id: deviceId,
      },
    }).then((response) => {
      const { juvoDevice } = response?.data;
      res && res({ juvoDevice });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static signIn(userData, res, error) {
    const client = ConnectionService.getGraphqClient();
    client.mutate({
      mutation: signInJuvoUser,
      variables: {
        email: userData.email,
        password: userData.password,
      },
    }).then((response) => {
      const { token, user } = response?.data?.signInJuvoUser;
      res && res({ token, user });
    })
      .catch((e) => {
        error && error(e);
      });
  }
  

  static juvoUserbyEmail(userData, res, error) {
    const client = ConnectionService.getGraphqClient();
    client.query({
      query: juvoUserbyEmail,
      variables: {
        email: userData.email,
        change: userData.change
      },
    }).then((response) => {
      const { juvoUserbyEmail } = response?.data;

      res && res({ juvoUserbyEmail });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static sendPushLock(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: sendPushLockUnlock,
      variables: {
        gsfId: actionData.gsfId,
        isLock: actionData.isLock,
        idBrand: parseInt(actionData.idBrand),
      },
    }).then((response) => {
      const { sendPushLockUnlock } = response?.data;
      res && res({ sendPushLockUnlock });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }


  static sendPush(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: sendPush,
      variables: {
        gsfId: actionData.gsfId,
        idBrand: parseInt(actionData.idBrand),
        msg: actionData?.msg
      },
    }).then((response) => {
      const { sendPush } = response?.data;
      res && res({ sendPush });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static sendMultiplePush(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: sendMultiplePush,
      variables: {
        gsfIds: actionData?.gsfIds,
        msg: actionData?.msg,
        idBrand: parseInt(actionData?.idBrand)
      },
    }).then((response) => {
      const success = response?.data;
      res && res(success);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static generatePin(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: generatePin,
      variables: {
        gsfId: actionData.gsfId,
      },
    }).then((response) => {
      const { generatePin } = response?.data;
      res && res({ generatePin });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
  
  static updateJuvoUser(id, juvoUser, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: updateJuvoUser,
      variables: {
        id: id,
        input: juvoUser
      }
    }).then((response) => {
      const { juvoUser } = response?.data;
      res && res({ juvoUser });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }



  static createJuvoUser(juvoUser, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: createJuvoUser,
      variables: {
        input: juvoUser
      }
    }).then((response) => {
      const { juvoUser } = response?.data;
      res && res({ juvoUser });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
  
  static juvoDeviceStats(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDeviceStats,
      variables: {
        risky: actionData?.risky,
      }
    }).then((response) => {
      const { juvoDeviceStats } = response?.data;
      res && res({ juvoDeviceStats });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
  static getCustomers(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoCustomers,
      variables: {
        start: actionData?.start,
        limit: actionData?.limit,
        search: actionData?.search,
      },
    }).then((response) => {
      const { juvoCustomers } = response?.data;
      res && res({ juvoCustomers });
    })
      .catch((e) => {
        error && error(e);
      });
  }
  static reportJuvoEvent(juvoEventHistory, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
    const id = user?.id;
    if(!juvoEventHistory.created_by) 
      juvoEventHistory.created_by = parseInt(id);

    if(!juvoEventHistory.created_at)
      juvoEventHistory.created_at = now().toString()

    client.mutate({
      mutation: reportJuvoEvent,
      variables: {
        input: juvoEventHistory
      }
    }).then((response) => {
      const { id } = response?.data;
      res && res(id);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }


  static devicebyOwner(id, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: devicebyOwner,
      variables: {
        id: id,
      },
    }).then((response) => {
      const { devicebyOwner } = response?.data;
      res && res({ devicebyOwner });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
    }

  static getUserNotifications(res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
    const id = user?.id;
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoNotificationsByUser,
      variables: {
        id_user: id
      }
    }).then((response) => {
      const { juvoNotificationsByUser } = response?.data;
      res && res(juvoNotificationsByUser)
    }).catch((e) => {
      error && error(e)
    });
  }

  static markAsSeenNotification(id, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: markAsSeenNotification,
      variables: {
        id: id
      }
    }).then((response) => {
      const data = response?.data;
      res && res(data)
    }).catch((e) => {
      error && error(e)
    });
  }

  static createJuvoCustomer(juvoCustomer, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);

    if(!juvoCustomer.created_by)
    {
      const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
      const id = user?.id;
      juvoCustomer.created_by = parseInt(id);
    }

    client.mutate({
      mutation: createJuvoCustomer,
      variables: {
        input: juvoCustomer
      }
    }).then((response) => {
      const { createJuvoCustomer } = response?.data;
      res && res(createJuvoCustomer);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static updateJuvoCustomer(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: updateJuvoCustomer,
      variables: {
        id: actionData.id,
        input: actionData.input
      }
    }).then((response) => {
      const { id } = response?.data;
      res && res(id);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static updateJuvoDevice(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: updateJuvoData,
      variables: {
        id: actionData.id,
        input: actionData.input
      }
    }).then((response) => {
      const { id } = response?.data;
      res && res(id);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getCustomersBySearch(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoCustomerBySearch,
      variables: {
        fullname: actionData.fullname,
        start: actionData.start,
        limit: actionData.limit,
        fields: actionData.fields,
      },
    }).then((response) => {
      const { juvoCustomerBySearch } = response?.data;
      res && res(juvoCustomerBySearch);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getDashboardData(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoDashboardsData,
      variables:{
        dateRange: actionData?.dateRange,
        risky: actionData?.risky,
      },
    }).then((response) => {
      const { juvoDashboardsData } = response?.data;
      res && res(juvoDashboardsData);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static markAsSeenAllNotifications(res, error){
    const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
    const id = user?.id;
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: markAsSeenAllJuvoNotification,
      variables: {
        userId: parseInt(id)
      }
    }).then((response) => {
      const data = response?.data;
      res && res(data)
    }).catch((e) => {
      error && error(e)
    });   
  }

  static getEventHistory(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoEventHistory,
      variables:{
        start: actionData?.start,
        limit: actionData?.limit,
        startDate: actionData?.startDate,
        endDate: actionData?.endDate,
        search: actionData?.search,
        fields: actionData?.fields
      },
    }).then((response) => {
      const { juvoEventHistory } = response?.data;
      res && res(juvoEventHistory);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }

  static getEventType(actionData, res, error){
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.query({
      query: juvoEventTypes,
      variables:{
        start: actionData?.start,
        limit: actionData?.limit
      },
    }).then((response) => {
      const { juvoEventTypes } = response?.data;
      res && res(juvoEventTypes);
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  } 
  // Mutation for the methods
  static sendPushToForceUpdateTokenOhne(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: sendPushToForceUpdateTokenOhne,
      variables: {
        message:actionData.message
      },
    }).then((response) => {
      // const { sendPushToForceUpdateTokenOhne } = response?.data;
      // res && res({ sendPushToForceUpdateTokenOhne });
      // console.log(res)
    })
      .catch((e) => {
        // ServiceHelper.checkUnauthorized(e);
        // error && error(e);
        // console.log(e)
      });
  }
  //juvo methods
  static sendPushToForceUpdateTokenJuvo(actionData, res, error) {
    const jwt = localStorage.getItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    const client = ConnectionService.getGraphqClientWithJWT(jwt);
    client.mutate({
      mutation: sendPushToForceUpdateTokenJuvo,
      variables: {
        message:actionData.message
      },
    }).then((response) => {
      const { sendPushToForceUpdateTokenJuvo} = response?.data;
      res && res({ sendPushToForceUpdateTokenJuvo });
    })
      .catch((e) => {
        ServiceHelper.checkUnauthorized(e);
        error && error(e);
      });
  }
}