import { Avatar, Row, Table } from "antd";
import {
  MobileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import "antd/dist/antd.css";
import moment from "moment";
import { useEffect } from "react";
import Logo from '../../assets/juvo_logo.png';

export function HistoryTable({
  // onClick,
  isLoading,
  dataList,
  changePagination,
  totalItems,
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    total: totalItems,
    pageSize: 10
  });

  useEffect(() => {
    setPagination({
      current: pagination.current,
      total: totalItems,
      pageSize: pagination.pageSize
    });
  }, [dataList]);

  useEffect(() => {
    setPagination({
      current: 1,
      total: totalItems,
      pageSize: 10
    });
    const newPagination = {
      start: pagination.pageSize * (pagination.current - 1),
      limit: pagination.pageSize,
    };
    changePagination(newPagination);
  }, [totalItems]);

  const columns = [
    
    {
      title: "Event",
      dataIndex: "event_name",
      key: "event_name",
      align: "center",
    },
    {
      title: "Responsible",
      dataIndex: "user_email",
      key: "user_email",
      align: "start",
      render: (item, index) => {
        return (<>
          {(index.user_email !== 'genericuser' && index.user_fullname !== 'Generic User' && index.user_email !== 'None' && index.user_fullname !== 'None') ? 
            <Row align='middle'><Avatar size='small' icon={<img src={Logo}></img>} style={{marginInlineEnd:'10px'}}/>{index.user_fullname}</Row>
            :
            index.customer_fullname ? 
              <Row align='middle'><UserOutlined style={{marginInline:'8px'}}/>{index.customer_fullname}</Row> : 
              <Row align='middle'><MobileOutlined style={{marginInline:'8px'}}/>{index.gsfId}</Row>
            
          }</>
        );
      },
    },
    {
      title: "Affected device",
      dataIndex: "gsfId",
      key: "gsfId",
      align: "center",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
    },
  ];

  const data = dataList?.map((item, index) => {
    return {
      ...item,
      key: item?.id,
      action: { item, index },
      user_email: item?.user_email || "None",
      user_fullname: item?.user_fullname || "None",
      created_at: item?.created_at
        ? moment(new Date(parseFloat(item?.created_at))).format("M/D/Y hh:mma")
        : "None",
      updated_at: item?.updated_at
        ? moment(new Date(parseFloat(item?.updated_at))).format("M/D/Y hh:mma")
        : "None",
      customerPhoneNumber: item?.customerPhoneNumber || null,
      customer_status: item?.CustomerStatus || false,
    };
  });

  const handleTableChange = (p) => {
    setPagination({ current: p.current, total: pagination.total, pageSize: p.pageSize });
    const newPagination = {
      start: p.pageSize * (p.current - 1),
      limit: p.pageSize,
    };
    changePagination(newPagination);
  };

  return (
    <Table
      loading={isLoading || false}
      dataSource={data}
      columns={columns}
      pagination={pagination}
      scroll={{ y: "calc(100% - 450px)", x: "1000px" }}
      onChange={handleTableChange}
    />
  );
}
