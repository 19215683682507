import { Col, message, Row, Switch, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { LocalStorageKeys } from '../../../helpers';
import AlertWithoutFooter from '../../../components/alertwithoutfooter.component';
import ItemField from '../../../components/ItemField';
import { Loading } from '../../../components/loading.component';
import { ActionLockUnlock } from './actionlock.component';
import { deviceStyle } from './style';
import { JuvoService } from '../../../services/juvo'
import ServiceHelper from '../../../services/serviceHelper';
import { CustomerDetail } from '../../customers/customerdetail.componet';
import CustomerAssignment from '../customerassignment/customerassignment.modal';
const defaultWidthLabel = 100;

export function DeviceDetail({ onClose, deviceId }) {
  const PushStyle = {
    height: '60px',
    width: '100%',
  };

  const classes = deviceStyle();
  const [activeCustomerDetail, setActiveCustomerDetail] = useState(false)
  const [deviceWasUpdated, setDeviceWasUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deviceData, setDeviceData] = useState({
    id: undefined,
    gsfId: undefined,
    androidVersion: undefined,
    brand: undefined,
    model: undefined,
    buildNumber: undefined,
    tag: undefined,
    pin: undefined,
    status: undefined,
    waitingResponse: undefined,
    hasDeviceAdminPermission: undefined,
    hasOverlayPermission: undefined,
    hasUsageStatsPermission: undefined,
    customerId: undefined,
    CustomerStatus: undefined
  });
  const [customerId, setCustomerId] = useState()
  const [displayPin, setDisplayPin] = useState(false);
  const [userId, setUserId] = useState();
  const [actionSelected, setActionSelected] = useState(deviceData?.status?.toUpperCase() || 'UNLOCK' || 'PUSH');
  const [activeCustomerAssignment, setActiveCustomerAssignment] = useState(false)

  const handleOnClickApply = () => {
    // TODO: call query graphql to LOCK or UNLOCK
    sendAction();
  };

  const sendAction = () => {
    const isLock = actionSelected?.toUpperCase() === 'LOCK';
    const actionData = { gsfId: deviceData?.gsfId, isLock, idBrand: deviceData?.idBrand }

    JuvoService.reportJuvoEvent({
      event_id: isLock ? 2 : 3,
      updated_device: parseInt(deviceData.id),
      created_by: parseInt(userId)
    }, 
    (id) => {
    },
    (e) => {
    })

    setIsLoading(true);
    JuvoService.sendPushLock(actionData, ({ sendPushLockUnlock }) => {
      setIsLoading(false);
      if (!sendPushLockUnlock) {
        message.error('Ops. An error has ocurred!')
        return
      }
      setIsError(false);
      setDeviceWasUpdated(true)
      setDeviceData(sendPushLockUnlock);
      setActionSelected(sendPushLockUnlock?.status?.toUpperCase() || 'UNLOCK')
      message.success(`Device was ${isLock ? 'Locked' : 'Unlocked'} successfully`)
    }, (e) => {
      setIsLoading(true);
      setIsError(true);
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })
  };

  useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem(LocalStorageKeys.KEY_SAVE_USER_DATA))
  
    if(id === null)
      message.error('Sorry, an unexpected error ocurred. Contact technical support')
    else
      setUserId(id)

    // TODO: call to query graphql
    setIsLoading(true);
    JuvoService.getDeviceById(deviceId, ({ juvoDevice }) => {
      setIsLoading(false);
      setIsError(false);
      setDeviceData(juvoDevice);
      setActionSelected(juvoDevice?.status?.toUpperCase() || 'UNLOCK')
    }, (e) => {
      setIsLoading(true);
      setIsError(true);
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })
  }, [deviceId]);

  const PermissionComponent = () => (
    <div className={classes.containerTags}>
      <Tag color={deviceData?.hasDeviceAdminPermission === true ? 'green' : 'red'} key="dadmin">
        Device admin
      </Tag>
      <Tag color={deviceData?.hasOverlayPermission === true  ? 'green' : 'red'} key="doverlay">
        Overlay
      </Tag>
      <Tag color={deviceData?.hasUsageStatsPermission === true ? 'green' : 'red'} key="dusage">
        Usage stats
      </Tag>
    </div>
  )

  const StatusComponent = () => (
    <div>
      <Tag color={deviceData?.status?.toUpperCase() === 'LOCK' ? 'red' : 'green'} key="dstatus">
        {`${deviceData?.status?.toUpperCase()}ED` || 'UNLOCKED'}
      </Tag>
    </div>
  )

  const handleOnClose = () => {
    onClose && onClose({ isRestart: deviceWasUpdated, deviceData });
  };

  const sendPush = ()=>{

    var msg = document.getElementById('msg').value;

    if(msg==="")
    {
      message.error('Ops. The messagge is requerid!');
      return;
    }
    
    JuvoService.reportJuvoEvent({
      event_id:5,
      updated_device: parseInt(deviceData.id),
      created_by: parseInt(userId)
    }, 
    (id) => {
    },
    (e) => {
      
    })
    
    const actionData = { gsfId: deviceData?.gsfId, msg: document.getElementById('msg').value, idBrand: deviceData?.idBrand }

    setIsLoading(true);
    JuvoService.sendPush(actionData, ({ sendPush }) => {
      setIsLoading(false);
      if (!sendPush) {
        message.error('Ops. An error has ocurred!')
        return
      }
      setIsError(false);
      setDeviceWasUpdated(true)
      setDeviceData(sendPush);
      message.success(`Push notification was send successfully`)
    }, (e) => {
      setIsLoading(true);
      setIsError(true);
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })
  }

  const buttonSmall = {
    height: "20px",
    width: "auto",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"9pt"
  }

  const generatePin = ()=>{
    JuvoService.reportJuvoEvent({
      event_id:1,
      updated_device: parseInt(deviceData.id),
      created_by: parseInt(userId)
    }, 
    (id) => {
      message.success('Pin successfully generated')
    },
    (e) => {
      message.error('Sorry, an unexpected error occurred while generating the pin. Contact technical support')
    })

    setDisplayPin(true);
    //riesgoso por el offline
    /*const actionData = { gsfId: deviceData?.gsfId}

    setIsLoading(true);
    JuvoService.generatePin(actionData, ({ generatePin }) => {
      setIsLoading(false);
      if (!generatePin) {
        message.error('Ops. An error has ocurred!')
        return
      }
      setIsError(false);
      setDeviceWasUpdated(true)
      setDeviceData(generatePin);
      setDisplayPin(true);
      message.success(`New pin has been generated`)
    }, (e) => {
      setIsLoading(true);
      setIsError(true);
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })*/
  }

  const openCustomerDetail = () => {
    setCustomerId(parseInt(deviceData.customerId))
    setActiveCustomerDetail(true)
  }

  const openCustomerAssignment = () => {
    setActiveCustomerAssignment(true)
  }

  const markAsResponded = () => {
    JuvoService.updateJuvoDevice({id: deviceData.id, input: {
      waitingResponse: null
    }},() => {
      setDeviceData({...deviceData, waitingResponse: null})
      message.success('Device marked as responded')
      onClose && onClose({ isRestart: true, deviceData: {...deviceData, waitingResponse: null} });
    }, () => {
      message.error('Sorry, an unexpected error occurred while marking the device as responded. Contact technical support')
    })
  }

  const handleChangeRegistered = (e) => {
    JuvoService.updateJuvoDevice({id: deviceData.id, input: {
      isRegistered: e
    }},() => {
      JuvoService.reportJuvoEvent({
        event_id:8,
        updated_device: parseInt(deviceData.id),
        created_by: parseInt(userId)
      });
      setDeviceData({...deviceData, isRegistered: e})
      message.success('Device classified as registered')
    }, () => {
      message.error('Sorry, an unexpected error occurred. Contact technical support')
    })
  }

  const closeCustomerAssignmentModal = () => {
    setActiveCustomerAssignment(false);
    setIsLoading(true);
    JuvoService.getDeviceById(deviceId, ({ juvoDevice }) => {
      setIsLoading(false);
      setIsError(false);
      setDeviceData(juvoDevice);
      setDeviceWasUpdated(true);
      setActionSelected(juvoDevice?.status?.toUpperCase() || 'UNLOCK')
    }, (e) => {
      setIsLoading(true);
      setIsError(true);
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    });
  }

  return (
    <div>
      <AlertWithoutFooter
        title="Device Information"
        onClose={handleOnClose}
        style={{position:'absolute'}}
      >
        <>
          { isLoading ? (isError ? <div /> :  <Loading />) :
            <div>
              <div className={classes.containerItems}>
                <ItemField widthLabel={defaultWidthLabel} label="Client ID:" value={deviceData?.gsfId} />
                <ItemField widthLabel={defaultWidthLabel} label="Android:" value={deviceData?.androidVersion} />
              </div>
              <div className={classes.containerItems}>
                <ItemField widthLabel={defaultWidthLabel} label="Brand:" value={deviceData?.brand} />
                <ItemField widthLabel={defaultWidthLabel} label="Model:" value={deviceData?.model} />
              </div>
              <div className={classes.containerItems}>
                <ItemField widthLabel={defaultWidthLabel} label="Build #:" value={deviceData?.buildNumber} />
                <ItemField widthLabel={defaultWidthLabel} label="Tag:" value={deviceData?.tag} />
              </div>
              <div className={classes.containerItems}>
                { displayPin ? 
                  <ItemField widthLabel={defaultWidthLabel} label="Pin:" value={<div style={{display:"flex"}}>
                  <p style={{width:"100px"}} className={classes.textBold}>{deviceData?.pin}</p>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-block"
                    style={buttonSmall}
                    onClick={() => setDisplayPin(false)}
                  >Hide pin
                  </button>
                  </div>} /> :
                  <ItemField widthLabel={defaultWidthLabel} label="Pin:" value={<button
                    type="button"
                    className="btn btn-sm btn-primary btn-block"
                    style={buttonSmall}
                    onClick={() => generatePin()}
                  >
                    Generate pin
                  </button>} /> 
                }
                <ItemField widthLabel={defaultWidthLabel} label="Permissions:" value={<PermissionComponent />} />
              </div>
              <div className={classes.containerItems}>
                <ItemField widthLabel={defaultWidthLabel} label="Status:" value={<StatusComponent />} />
                <ItemField widthLabel={defaultWidthLabel} label="Push Notification:" value={<Row style={{width:'100%'}}><textarea 
                  type="search" 
                  className="form-control form-control-sm" 
                  maxLength={150} 
                  placeholder="Send Push" 
                  style={{PushStyle}} id="msg"/> </Row> }/>
                  
              </div>

              <div className={classes.containerItems}>
                <ItemField widthLabel={defaultWidthLabel} label="Requested call:" value={<Row>
                  <Col>
                    <p className={classes.textBold}>{deviceData?.waitingResponse ? 'YES' : 'NO'}</p>
                  </Col>
                  {deviceData?.waitingResponse && 
                    <Col style={{marginInlineStart:'20px'}}>
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary btn-block"
                        style={buttonSmall}
                        onClick={markAsResponded}
                      > Mark as responded                      
                      </button>
                    </Col>
                  }
                </Row>} />
                <ItemField widthLabel={defaultWidthLabel} label={null} value={<button
                    style={buttonSmall}
                    type="button" 
                    className="btn btn-sm btn-primary"
                    onClick={sendPush}
                    >
                      Send Push
                    </button>
                } />
              </div>
              <div className={classes.containerItemAction}>
                <ItemField
                  widthLabel={250}
                  label="Device registered by Juvo:"
                  value={
                    <Switch
                      checked={deviceData?.isRegistered}
                      onChange={(e) => handleChangeRegistered(e)}
                      disabled={deviceData?.isRegistered === null || deviceData?.isRegistered === true}
                    />
                  }
                />
                <ItemField
                  widthLabel={defaultWidthLabel}
                  label="Customer:"
                  value={
                    deviceData?.customerId ? 
                    <button type='button' className="btn btn-sm btn-primary" onClick={openCustomerDetail}>View details</button> :
                    <button type='button' className="btn btn-sm btn-primary" onClick={openCustomerAssignment}>Assign to customer</button> 
                  }
                />
              </div>
              <div className={classes.containerItemAction}>
                <ItemField
                  widthLabel={defaultWidthLabel}
                  label="Actions:"
                  value={
                    <ActionLockUnlock
                      actionSelected={actionSelected}
                      onActionSelect={(action) => { setActionSelected(action) }}
                      onClickApply={handleOnClickApply}
                    />
                  }
                />
              </div>
              <hr/>
            </div>
          }
        </>
      </AlertWithoutFooter>
      {
        activeCustomerDetail && <CustomerDetail customerId={customerId} onClose={() => setActiveCustomerDetail(false)}/>
      }
      {
        activeCustomerAssignment && <CustomerAssignment deviceId={deviceData?.id} onClose={closeCustomerAssignmentModal}/>
      }
      
    </div>
  );
}