import {
  Layout,
  Avatar,
  Badge,
  message,
  Col,
  Row,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { BellOutlined } from "@ant-design/icons";
import { LocalStorageKeys } from "../helpers/enum.helpers";
import { JuvoService } from "../services/juvo";
import Logo from "../assets/juvo_logo.png";
import ServiceHelper from "../services/serviceHelper";
import NotificationPanel from "./notificationpanel.component";

const { Header } = Layout;

export default function TopMenuBar(props) {
  const [userData, setUserData] = useState({
    id: undefined,
    fullname: undefined,
    email: undefined,
  });

  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(0);
  const [visibleNotification, setVisibleNotification] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    localStorage.removeItem(LocalStorageKeys.KEY_SAVE_USER_DATA);
    window.location.href = "/login";
  };

  const getNotifications = () => {
    JuvoService.getUserNotifications(
      ({ totalCount }) => {
        setNotificationCount(totalCount);
      },
      (e) => {
        message.error(
          ServiceHelper.getErrorMessageFromGraphql(e) ||
            "Ops. An error has ocurred!"
        );
      }
    );
  };

  useEffect(() => {
    if(!userData.fullname)
    {
      const userLocal = JSON.parse(
        localStorage.getItem(LocalStorageKeys.KEY_SAVE_USER_DATA)
      );
  
      if (userLocal === null) return;
  
      const { id } = userLocal;
      setUserData(userLocal);
      JuvoService.getJuvoUser(
        id,
        ({ juvoUser }) => {
          setUserData(juvoUser);
        },
        (e) => {}
      );
    }
    
    getNotifications();
  }, []);

  useEffect(() => {
    history.listen(() => getNotifications());
  },[history])

  const showNotifications = () => {
    setVisibleNotification(true);
  };

  return (
    <Header
      className="site-layout-background"
      style={{ background: props.darkTheme ? "" : "white" }}
    >
      <Row style={{ justifyContent: "right" }} align="middle">
        <Col style={{ marginInline: "20px" }}>
          <Row align="middle">
            <Link
              style={{ color: props.darkTheme ? "white" : "black" }}
              to="/account/general"
            >
              <Avatar
                size={40}
                icon={<img src={Logo} alt="Ohne" />}
                style={{ cursor: "pointer", marginRight: "10px" }}
              />
            </Link>
            <h6 className="device-username">
              <Link
                style={{ color: props.darkTheme ? "white" : "black" }}
                to="/account/general"
              >
                {userData?.fullname || "None"}
              </Link>
            </h6>
          </Row>
        </Col>
        <div
          className="device-vertical-separator"
          style={{ backgroundColor: props.darkTheme ? "white" : "black" }}
        />
        <Col style={{ marginInline: "20px" }}>
          <Badge
            count={notificationCount}
            size="small"
            onClick={showNotifications}
            offset={[-8, 3]}
            style={{ cursor: "pointer" }}
          >
            <BellOutlined
              style={{
                fontSize: "28px",
                color: props.darkTheme ? "white" : "black",
                cursor: "pointer",
              }}
            />
          </Badge>
        </Col>
        <div
          className="device-vertical-separator"
          style={{ backgroundColor: props.darkTheme ? "white" : "black" }}
        />
        <Col style={{ marginInline: "20px" }}>
          <h6
            style={{
              color: props.darkTheme ? "white" : "black",
              cursor: "pointer",
            }}
            className="device-logout"
            onClick={handleLogout}
          >
            Logout
          </h6>
        </Col>
      </Row>
      <NotificationPanel
        visible={visibleNotification}
        onClose={() => { setVisibleNotification(false); getNotifications(); }}
      />
    </Header>
  );
}
