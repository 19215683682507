import React, { useEffect, useState } from "react";
import { useCallbackState } from "use-callback-state";
import { Row, Col, Card, Progress, message, Statistic, Divider, Typography, Spin, Radio, DatePicker, Space } from "antd";

import { CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import { JuvoService } from "../../services/juvo";
import ServiceHelper from "../../services/serviceHelper";
import moment from "moment";
import { AreaChart } from "recharts/lib/chart/AreaChart";
import { Area } from "recharts/lib/cartesian/Area";
import { PieChart } from "recharts/lib/chart/PieChart";
import { Pie } from "recharts/lib/polar/Pie";
import { Cell } from "recharts/lib/component/Cell";
import { LabelList } from "recharts/lib/component/LabelList";
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Title } = Typography;

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    newDevices: [{ name: undefined, Devices: 0 }],
    totalDevices: [{ name: undefined, Devices: 0 }],
  });
  const [statusDashboard, setStatusDashboard] = useState([
    {
      name: "",
      value: 0,
    },
  ]);

  const [totalDevices, setTotalDevices] = useState(0);
  const [lockDevices, setLockDevices] = useState(0);
  const [unlockDevices, setUnlockDevices] = useState(0);
  const [waitingResponse, setwaitingResponse] = useState(0);
  const [isLoadingNewDevices, setIsLoadingNewDevices] = useState(true);
  const [hasSetupPermissions, sethasSetupPermissions] = useState(0);
  const [isLoadingTargets, setIsLoadingTargets] = useState(true);
  const [dateFormat, setDateFormat] = useState('Per Day');

  const [dateRange, setDateRange] = useState([new moment().subtract(7, 'days'), new moment()]);
  const [risky, setRisky] = useCallbackState(false, (event) => {
    setIsLoadingTargets(true);
    JuvoService.juvoDeviceStats(
      { risky: event },
      ({ juvoDeviceStats }) => {
        setTotalDevices(juvoDeviceStats.totalDevices);
        setwaitingResponse(juvoDeviceStats.requestedCallDevices);
        setLockDevices(juvoDeviceStats.lockedDevices);
        setUnlockDevices(juvoDeviceStats.unlockedDevices);
        sethasSetupPermissions(juvoDeviceStats.hasSetupPermissions);
        setStatusDashboard([
          { name: "Locked-Devices", value: juvoDeviceStats.lockedDevices },
          { name: "Unlocked-Devices", value: juvoDeviceStats.unlockedDevices },
        ]);
        setIsLoadingTargets(false);
      },
      (e) => {
        message.error("Error fetching device stats");
        setIsLoadingTargets(false);
      }
    );
    return event;
  });

  

  useEffect(() => {
    setIsLoadingNewDevices(true);
    JuvoService.getDashboardData(
      { dateRange, risky },
      (juvoDashboardsData) => {
        setDashboardData({
          totalDevices: juvoDashboardsData.totalDevicesByDate
            .reverse()
            .map((item) => ({
              name: moment(new Date(parseFloat(item.date))).format(juvoDashboardsData.dateFormat),
              Devices: item.count,
            })),
          newDevices: juvoDashboardsData.newDevicesByDate
            .reverse()
            .map((item) => ({
              name: moment(new Date(parseFloat(item.date))).format(juvoDashboardsData.dateFormat),
              Devices: item.count,
            })),
        });
        if(juvoDashboardsData.dateFormat === 'DD'){
          setDateFormat('Per Day')
        } else if(juvoDashboardsData.dateFormat === 'DD/MM'){
          setDateFormat('Per Week')
        } else if(juvoDashboardsData.dateFormat === 'MM/YYYY'){
          setDateFormat('Per Month')
        } else if(juvoDashboardsData.dateFormat === 'YYYY'){
          setDateFormat('Per Year')
        }
        setIsLoadingNewDevices(false);
      },
      (e) => {
        message.error(
          ServiceHelper.getErrorMessageFromGraphql(e) ||
            "Ops. An error has ocurred!"
        );
      }
    );
  },[risky, dateRange]);

  useEffect(() => {
    setRisky(false);
  },[])

  return (
    <div style={{ minWidth: "1000px" }}>
      <Row>
        <Col span={24}>
          <Divider orientation="left">
            <Row justify="center">
              <Col>
                <Radio.Group
                  value={risky}
                  buttonStyle="solid"
                  onChange={(e) => setRisky(e.target.value)}
                >
                  <Radio.Button value={null}>All Devices</Radio.Button>
                  <Radio.Button value={false}>Active Devices</Radio.Button>
                  <Radio.Button value={true}>Inactive Devices</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Divider>
        </Col>
      </Row>

      <Row align="middle" justify="center" gutter={[18, 0]}>
        <Col span={4}>
          <Card style={{ justifyContent: "center", display: "flex" }}>
            <Spin spinning={isLoadingTargets}>
              <Statistic
                title="Device numbers"
                value={totalDevices}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "xxx-large !important",
                }}
              />
            </Spin>
          </Card>
        </Col>
        <Col span={5}>
          <Card style={{ justifyContent: "center", display: "flex" }}>
            <Spin spinning={isLoadingTargets}>
              <Statistic
                title="Unlocked Devices"
                value={unlockDevices}
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "xxx-large !important",
                }}
              />
            </Spin>
          </Card>
        </Col>
        <Col span={5}>
          <Card style={{ justifyContent: "center", display: "flex" }}>
            <Spin spinning={isLoadingTargets}>
              <Statistic
                title="Locked Devices"
                value={lockDevices}
                style={{
                  color: "Green",
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "xxx-large !important",
                }}
              />
            </Spin>
          </Card>
        </Col>
        <Col span={5}>
          <Link to="/devices/all/permissionsoff">
            <div title="Go to devices list! This devices have deactivate permissions">
              <Card
                style={{
                  justifyContent: "center",
                  display: "flex",
                  color: "red",
                  border: "1px solid red",
                  backgroundColor: "#f1540f26",
                }}
              >
                <Spin spinning={isLoadingTargets}>
                  <Statistic
                    title="Permissions OFF"
                    value={hasSetupPermissions}
                    style={{
                      fontWeight: "bolder",
                      color: "red",
                      textAlign: "center",
                      fontSize: "xxx-large !important",
                    }}
                  />
                </Spin>
              </Card>
            </div>
          </Link>
        </Col>
        <Col span={5} title="This devices had a make request to call back">
          <Card
            style={{
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#ffff004d",
              cursor: "pointer",
            }}
          >
            <Spin spinning={isLoadingTargets}>
              <Statistic
                title="Waiting for response"
                value={waitingResponse}
                style={{
                  color: "Green",
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "xxx-large !important",
                }}
              />
            </Spin>
          </Card>
        </Col>
      </Row>

      <Row gutter={[10, 0]} style={{ marginTop: "10px" }} align="middle">
        <Col span={14}>
          <Card
            title={
              <Row justify="space-between">
                <Col>
                  <Title level={4}>Total { risky ? 'Inactive' : risky === false && 'Active' } Devices {dateFormat}</Title>
                </Col>
                <Col>
                  <Space direction="vertical" size={8}>
                    <RangePicker value={dateRange} onChange={e => setDateRange(e)}/>
                  </Space>
                </Col>
              </Row>
            }
          >
            <Spin size="large" spinning={isLoadingNewDevices}>
              <div
                style={{
                  width: "100%",
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AreaChart
                  width={547}
                  height={200}
                  data={dashboardData.totalDevices}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="40%"
                        stopColor="#00B678"
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="80%"
                        stopColor="#82ca9d"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />

                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Devices"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </div>
            </Spin>
          </Card>
        </Col>
        <Col span={10}>
          <Card
            style={{ justifyContent: "center", textAlign: "center" }}
            title="Locked and unlocked devices"
          >
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin spinning={isLoadingTargets}>
                <PieChart width={200} height={232}>
                  <Pie
                    data={statusDashboard}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={true}
                    outerRadius="100%"
                    fill="#8884d8"
                    dataKey="value"
                  >
                    <Cell key={`cell-0`} fill="red" label>
                      <LabelList dataKey="name" />
                    </Cell>
                    <Cell key={`cell-1`} fill="#00B678" label>
                      <LabelList dataKey="name" />
                    </Cell>
                    <Tooltip />
                  </Pie>
                </PieChart>
              </Spin>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[10, 0]} style={{ marginTop: "10px" }}>
        <Col span={14}>
          <Card
            title={
              <Row justify="space-between">
                <Col>
                  <Title level={4}>New { risky ? 'Inactive' : risky === false && 'Active' } Devices {dateFormat}</Title>
                </Col>
                <Col>
                  <Space direction="vertical" size={8}>
                    <RangePicker value={dateRange} onChange={e => setDateRange(e)}/>
                  </Space>
                </Col>
              </Row>
            }
          >
            <Spin size="large" spinning={isLoadingNewDevices}>
              <div
                style={{
                  width: "100%",
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AreaChart
                  width={547}
                  height={250}
                  data={dashboardData.newDevices}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="40%"
                        stopColor="#00B678"
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="80%"
                        stopColor="#82ca9d"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />

                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Devices"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </div>
            </Spin>
          </Card>
        </Col>
        <Col span={10}>
          <Row>
            <Card
              style={{
                textAlign: "center",
                width: "100%",
                height: "185px",
              }}
              bodyStyle={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row align="middle">
                <Col span={13}>
                  <Spin spinning={isLoadingTargets}>
                    <Progress
                      type="circle"
                      percent={
                        lockDevices && totalDevices
                          ? ((lockDevices / totalDevices) * 100).toFixed(2)
                          : 0
                      }
                      showInfo={true}
                      strokeColor="Red"
                    />
                  </Spin>
                </Col>
                <Col span={8}>
                  <Title level={4}>Percentage of locked devices</Title>
                </Col>
              </Row>
            </Card>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Card
              style={{
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                height: "185px",
              }}
              bodyStyle={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row>
                <Col span={8}>
                  <Title level={4}>Percentage of unlocked devices</Title>
                </Col>
                <Col span={13}>
                  <Spin spinning={isLoadingTargets}>
                    <Progress
                      type="circle"
                      percent={
                        unlockDevices && totalDevices
                          ? ((unlockDevices / totalDevices) * 100).toFixed(2)
                          : 0
                      }
                      strokeColor="#00B678"
                    />
                  </Spin>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
