import { LoadingOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core';
import { Spin } from 'antd';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    marginTop: '45px',
    marginBottom: '45px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  buttonCapitalize: {
    textTransform: 'capitalize',
    height: 'auto',
  },
}));


export default function ButtonLoading({ loading, onClick, children }) {
  const classes = useStyles();

  const classButton = `${classes.buttonCapitalize} btn btn-primary btn-block`;

  const antIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {!loading && <button
          onSubmit={(e) => { e.preventDefault() }}
          variant="contained"
          color="primary"
          className={classButton}
          disabled={loading}
          onClick={(e) => {
            e.preventDefault()
            onClick && onClick();
          }}
        >
          {children}
        </button> }
        {loading && <Spin indicator={antIcon} />}
      </div>
    </div>
  )
}