import {Modal} from "antd";
import React from "react";

export function  ResetModal({ visible, onClose, password }) {
    const {  useState  } = React;

  const handleClose = () => {
    onClose();
    visible=false;
  };

  return (
    <div>
     <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
        </span>
      </div>
      <Modal
        visible={visible}
        title="New Password"
        footer={null}
        onCancel={onClose}
      >
          The new password is <strong>{password.password}</strong>, please share with the customer.
          <br/>
          Email: {password.email}
      </Modal>
    </div>
  );
}