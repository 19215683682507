import React from "react";
import { Radio } from "antd";
import { deviceStyle } from "./style";

export function ActionLockUnlock({ actionSelected, onActionSelect, onClickApply }) {
  const classes = deviceStyle();
  const handleSizeChange = (e) => {
    onActionSelect && onActionSelect(e.target.value);
  }



  return (
    <div className={classes.containerAction}>
      <Radio.Group value={actionSelected || 'UNLOCK'} onChange={handleSizeChange}>
        <Radio.Button value="UNLOCK">UNLOCK</Radio.Button>
        <Radio.Button value="LOCK">LOCK</Radio.Button>
      </Radio.Group>
      <button
        type="button"
        className="btn btn-primary btn-block btn-sm"
        onClick={() => { onClickApply && onClickApply() }}
      >
        Apply
      </button>
    </div>
  );
}