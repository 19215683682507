import React from "react";
import { Avatar,Row, Col, Card, Divider  } from "antd";
import Logo from "../../assets/juvo_logo.png";

export default function GeneralInformationForm(props) {
  return (
    <div style={{textAlign:"center"}}>
      <Row justify="space-around" align="middle">
        <Col span={5}>
          <Card style={{ justifyContent: "center", textAlign:"center" }}>
            <Avatar size={100} icon={<img src={Logo} alt="Ohne"/>}></Avatar>
          </Card>
        </Col>    
      </Row> 
      <Divider/>
      <h3>General Information</h3>  
      <Row justify="space-around" align="middle">
        <Col span={15}>
          <Row justify="space-around" align="middle">
              <Col span={30}>
              <Card style={{ justifyContent: "justify", textAlign:"justify" }}>
              <p style={{fontWeight:"bold"}}>Name: {props.userData.fullname}</p>
              <p style={{fontWeight:"bold"}}>Email: {props.userData.email}</p>
                </Card>
              </Col>            
          </Row>
        </Col>
      </Row>
    </div>
  );
}
