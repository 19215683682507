import React, { useCallback, useEffect, useState } from "react";
import { DeviceTable } from "./devicetable.component";
import './devicestyle.css';
import { DeviceDetail } from "./devicedetail/devicedetail.component";
import { JuvoService } from "../../services/juvo/juvo.service";
import ServiceHelper from "../../services/serviceHelper";
import { Col, Divider, message, Radio, Row, Tag } from "antd";
import { MultiplePushNotifications } from "./devicedetail/pushnotifications.component";
import { useParams } from 'react-router-dom'
import { sendPushToForceUpdateTokenJuvo, sendPushToForceUpdateTokenOhne } from "../../services/schemas";


export function MainDevice() {
  const { dangerousness, filterDefault } = useParams()
  const [riskyDevices, setRiskyDevices] = useState()
  const [isDeviceDetailVisible, setIsDeviceDetailVisible] = useState(false);
  const [deviceDetail, setDeviceDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, limit: 10 });
  const [totalDevices, setTotalDevices] = useState(0);
  const [searchText, setSearchText] = useState();
  const [isMultiplePushVisible, setMultiplePushVisible] = useState(false);
  const [filter, setFilter] = useState({})
  const [isSearch, setIsSearch] = useState(false);
  const [deviceStats, setDeviceStats] = useState({
    totalDevices: undefined,
    requestedCallDevices: undefined,
    lockedDevices: undefined,
    unlockedDevices: undefined,
    hasSetupPermissions: undefined,
    riskyDevices: undefined,
  });


  const getDevices = useCallback(() => {
    // Get device list
    setIsLoading(true)
    setIsSearch(false)
    setDeviceList([])
    JuvoService.getDevicesWithCustomers( { ...pagination, fields: filter, risky: riskyDevices
    }, ({ devices, totalCount }) => {
      setTotalDevices(totalCount)
      setDeviceList(devices)
      setIsLoading(false)
    }, (e) => {
      setIsLoading(false)
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!')
    })
  },[pagination, filter, riskyDevices])

  const getDevicesBySearch = () => {
    setIsLoading(true);
    JuvoService.getDevicesBySearch(searchText || "", filter, riskyDevices, ({ juvoDeviceBySearch }) => {
      setTotalDevices(juvoDeviceBySearch.length);
      setDeviceList(juvoDeviceBySearch);
      setIsLoading(false);
    }, (e) => {
      setIsLoading(false)
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })
  }

  useEffect(() => {
    let risky = null;
    if(dangerousness === "active")
      risky = false;
    else if(dangerousness === "inactive")
      risky = true;
    
    setRiskyDevices(risky)

    JuvoService.juvoDeviceStats({risky},
      ({ juvoDeviceStats }) => {
        setDeviceStats(juvoDeviceStats);
      },
      (e) => {
        message.error(
          ServiceHelper.getErrorMessageFromGraphql(e) ||
            "Ops. An error has ocurred!"
        );
      }
    );

    if(filterDefault === "unlock")
      setFilter({ status:'Unlock'})
    else if(filterDefault === "lock")
      setFilter({ status: 'Lock'})
    else if(filterDefault === "waitingresponse")
      setFilter({ waitingResponse: true})
    else if(filterDefault === "permissionsoff")
      setFilter({ hasDeviceAdminPermission: false})

      // getDevices();
  }, []);

  useEffect(() => {
    getDevices();
  }, [pagination])

  useEffect(() => {
    if(isSearch)
      getDevicesBySearch();
    else
      getDevices();
  },[filter])

  const onClickRow = (data, index) => {
    setIsDeviceDetailVisible(true)
    setDeviceDetail(data)
  };

  const handleOnCloseDeviceDetail = ({ isRestart, deviceData }) => {
    setIsDeviceDetailVisible(false)
    setDeviceDetail({})

    if (isRestart) {
      getDevices();
    }
  };

  const handleChangePassword = (e) => {
    const search = e.target.value;
    setSearchText(search);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      getDevicesBySearch();
    }
  };

  const onClickSearch = () => {
    setIsSearch(true);
    getDevicesBySearch();
  }

  const onClickClear = () => {
    const newPagination = { ...pagination };
    setPagination(newPagination)
    setSearchText('')
    getDevices();
  }
  
  const onClickUpdateToForceTokenOhne = () =>{
    //sendPushToForceUpdateTokenOhne();
  }
  const onClickUpdateToForceTokenJuvo = () =>{
    console.log("estamos en el metodo")
    // sendPushToForceUpdateTokenJuvo()
  }
  return (
    <div className="site-layout-content" style={{ minHeight:"600px"}}>
      {
        isMultiplePushVisible && <MultiplePushNotifications visible={isDeviceDetailVisible} close={() => setMultiplePushVisible(false)}/>
      }
      {isDeviceDetailVisible &&
        <DeviceDetail
          onClose={handleOnCloseDeviceDetail}
          deviceId={deviceDetail.id}
        />
      }
      <Row>
      <Col style={{marginBottom:'20px'}}>
        <input
            type="search"
            className="form-control form-control-sm"
            placeholder="Find device by params"
            onChange={handleChangePassword}
            onKeyDown={keyPress}
            value={searchText}
          />
        </Col>
        <Col style={{marginInlineStart:'20px', marginBottom:'20px'}}>
          <button type="button" className="btn btn-primary btn-block btn-sm" onClick={onClickSearch}>Search</button>
        </Col>
        <Col style={{marginInlineStart:'20px', marginBottom:'20px'}}>
          <button type="button" className="btn btn-secondary btn-block btn-sm" onClick={onClickClear}>Clear</button>
        </Col>
        <Col style={{marginInlineStart:'20px', marginBottom:'20px'}}>
          <button type="button" onClick={() => setMultiplePushVisible(true)} className="btn btn-primary btn-block btn-sm" >Send Multiple Push Notifications</button>
        </Col>
        <Col style={{marginInlineStart:'20px', marginBottom:'20px'}}>
          <button type="button" className="btn btn-primary btn-block btn-sm" onClick={onClickUpdateToForceTokenOhne}>Force token ohne</button>
        </Col>
        <Col style={{marginInlineStart:'20px', marginBottom:'20px'}}>
          <button type="button" className="btn btn-danger btn-block btn-sm" onClick={onClickUpdateToForceTokenJuvo}>Force token juvo</button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Radio.Group defaultValue={filterDefault}>
            <Radio.Button onClick={() => setFilter({})} value='all'>All <Tag>{deviceStats.totalDevices}</Tag></Radio.Button>
            <Radio.Button onClick={() => setFilter({ status:'Unlock'})} value='unlocked'>Unlocked Devices <Tag>{deviceStats.unlockedDevices}</Tag></Radio.Button>
            <Radio.Button onClick={() => setFilter({ status: 'Lock'})} value='locked'>Locked Devices <Tag>{deviceStats.lockedDevices}</Tag></Radio.Button>
            <Radio.Button onClick={() => setFilter({ waitingResponse: true})} value='waitingResponse'>Waiting for Response <Tag>{deviceStats.requestedCallDevices}</Tag></Radio.Button>
            <Radio.Button onClick={() => setFilter({ hasDeviceAdminPermission: false})} value='permissionsoff'>Permissions Off <Tag>{deviceStats.hasSetupPermissions}</Tag></Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Divider/>
      { riskyDevices && <p style={{color:'red'}}>These are the devices with no connection in the last 24 hours, probably the application has been uninstalled, please contact with the user of the device.</p>}
      <div className="border-device-table margin-device-screen" style={{width:"100%"}}>
        <DeviceTable
          isLoading={isLoading}
          dataList={deviceList}
          onClick={onClickRow}
          isSearch={isSearch}
          totalDevices={totalDevices}
          changePagination={(pagination) => { setPagination(pagination) }}
        />
      </div>
    </div>
  )
}