import React from 'react';
import error404 from '../assets/Error404.svg';
//import '../login/styles.css';

function Text404() {
  return (
    <div
      style={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img style={{ margin: 'auto', width: 250, height: 250 }} src={error404} alt="" className="img" />
    </div>
  );
}

export default Text404;
