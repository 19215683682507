import React, { useEffect, useState } from "react";
import { JuvoService } from "../../services/juvo/juvo.service";
import { message } from "antd";
import CustomerTable from "./customertable.component";
import { CustomerDetail } from "./customerdetail.componet";
import CustomerModal from "./add/customermodal.component";

export default function Customers() {
  const [isLoading, setIsLoading] = useState(false);
  const [CustomerList, setCustomerList] = useState([]);
  const [pagination, setPagination] = useState({ start: 0, limit: 10 });
  const [totalDevices, setTotalDevices] = useState(0);
  const [searchText, setSearchText] = useState();
  const [isCustomerDetailVisible, setIsCustimerDetailVisible] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);

  useEffect(() => {
    // Get device list
    setIsLoading(true);
    setCustomerList([]);
    JuvoService.getCustomers(pagination, ({ juvoCustomers }) => {
        setTotalDevices(juvoCustomers.totalCount);
        setCustomerList(juvoCustomers);
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        message.error("Ops. An error has ocurred!");
      }
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setCustomerList([]);
    JuvoService.getCustomers(pagination, ({ juvoCustomers }) => {
        setTotalDevices(juvoCustomers.totalCount);
        setCustomerList(juvoCustomers);
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        message.error("Ops. An error has ocurred!");
      }
    );
  }, [pagination]);

  const onClickRow = (data, index) => {
    setIsCustimerDetailVisible(true);
    setCustomerDetail(data);
  };

  const handleOnCloseCustomerDetail = ({ isRestart, deviceData }) => {
    setIsCustimerDetailVisible(false);
    setCustomerDetail({});

    if (isRestart) {
      const index = CustomerList.findIndex((x) => x.id === deviceData.id);
      CustomerList[index] = deviceData;
      const newDeviceData = [...CustomerList];
      setCustomerList(newDeviceData);
    }
  };

  const searchCustomer = () => {
    setIsLoading(true)
    setCustomerList([]);
    JuvoService.getCustomersBySearch({ fullname: searchText },(result) => {
      setCustomerList({ customers: result})
      setTotalDevices(result.length)
      setIsLoading(false)
    })
  }

  const clearSearch = () => {
    setIsLoading(true);
    setCustomerList([]);
    JuvoService.getCustomers(
      pagination,
      ({ juvoCustomers }) => {
        setTotalDevices(juvoCustomers.totalCount);
        setCustomerList(juvoCustomers);
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        message.error("Ops. An error has ocurred!");
      }
    );
  }

  const refreshData = () => {
    setIsLoading(true);
    setCustomerList([]);
    JuvoService.getCustomers(
      pagination,
      ({ juvoCustomers }) => {
        setTotalDevices(juvoCustomers.totalCount);
        setCustomerList(juvoCustomers);
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        message.error("Ops. An error has ocurred!");
      }
    );
  }
  return (
    <div className="site-layout-content" style={{ minHeight: "600px" }}>
      {isCustomerDetailVisible && (
        <CustomerDetail
          isLoading={isLoading}
          onClose={handleOnCloseCustomerDetail}
          customerId={customerDetail.id}
          fullname = {customerDetail.fullname}

        />
      )}
      <CustomerModal
        visible={isCustomerModalVisible}
        refreshData = {refreshData}
        onClose={() => setIsCustomerModalVisible(false)}
      />
      <div className="header-device">
        <div className="form-search-gsfid">
          <input
            type="search"
            className="form-control form-control-sm"
            placeholder="Find by name"
            value={searchText}
            onChange={(e) => {setSearchText(e.target.value)}}
          />
          <button type="button" className="btn btn-primary btn-block btn-sm" onClick={searchCustomer}>
            Search
          </button>
          <button type="button" className="btn btn-secondary btn-block btn-sm" onClick={clearSearch}>
            Clear
          </button>
          <button
            type="button"
            className="btn btn-info btn-block btn-sm"
            title="Add Customer"
            onClick={() => setIsCustomerModalVisible(true)}
          >
            Add
          </button>
        </div>
      </div>
      <div className="seperator-80" />
      <div className="border-device-table" style={{ width: "100%" }}>
        <CustomerTable
          isLoading={isLoading}
          onClick={onClickRow}
          dataList={CustomerList}
          refreshData = {refreshData}
          totalDevices={totalDevices}
          setPagination={(pagination) => {
            setPagination(pagination);
          }}
        />
      </div>
    </div>
  );
}
