import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  itemField: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    '& > p': {
      display: 'flex',
      fontWeight: 700,
      fontSize: '13px',
      fontFamily: 'system-ui',
      color: '#4C505B',
      maxWidth: '80px',
      flexGrow: 2,
      flexShrink: 1,
      overflowWrap: 'anywhere',
    },
    '& > div': {
      flexGrow: '100',
      display: 'flex',
      fontWeight: 400,
      fontSize: '13px',
      fontFamily: 'system-ui',
      overflowWrap: 'anywhere',
      color: '#4C505B',
    },
  },
}));

export default function ItemField({ label, value, widthLabel }) {
  const classes = useStyles();
  const width = widthLabel || 100;
  return (
    <>
      <div className={classes.itemField}>
        <p style={{ minWidth: width, maxWidth: width }}>{label}</p>
        <div>{value}</div>
      </div>
    </>
  );
}
