import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  UserOutlined,
  PieChartOutlined,
  AndroidOutlined,
  SettingOutlined,
  TeamOutlined,
  FireOutlined,
  SafetyOutlined,
  MobileOutlined,
  ClockCircleOutlined,
  SecurityScanOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import { LocalStorageKeys } from '../helpers/enum.helpers';

const { SubMenu } = Menu;
const { Sider } = Layout;

export default function LeftMenuBar(props) {
  const [collapsed, setCollapsed] = useState();
  const user = JSON.parse(localStorage.getItem(LocalStorageKeys?.KEY_SAVE_USER_DATA))
  const roleid = user?.roleid; //nuevo
  var rol = true;
  if(roleid===3)
  rol=false;

  const onCollapse = (value) => {
    setCollapsed(value);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
    localStorage.removeItem(LocalStorageKeys.KEY_SAVE_USER_DATA);
    window.location.href = "/login";
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme={props.darkTheme ? "dark" : "light"}
    >
      <Menu
        theme={props.darkTheme ? "dark" : "light"}
        defaultSelectedKeys={["1"]}
        mode="inline"
      >
        <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <SubMenu key="devices" icon={<AndroidOutlined />} title="Devices">
          <Menu.Item key="alldevices" icon={<MobileOutlined />}>
            <Link to={`/devices/all/all`}>All Devices</Link>
          </Menu.Item>
          <Menu.Item key="safedevices" icon={<SafetyOutlined />}>
            <Link to={`/devices/active/all`}>Active Devices</Link>
          </Menu.Item>
          <Menu.Item key="riskydevices" icon={<FireOutlined />}>
            <Link to={`/devices/inactive/all`}>Inactive Devices</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="customers" icon={<TeamOutlined/>}>
          <Link to="/customers">Customers</Link>
        </Menu.Item>        
        <Menu.Item key="event_history" icon={<ClockCircleOutlined />}>
          <Link to="/history/all">Event History</Link>
        </Menu.Item>
        <SubMenu key="user" icon={<UserOutlined />} title="User">
          <Menu.Item key="general">
            <Link to="/account/general">Account</Link>
          </Menu.Item>
          <Menu.Item key="security" onClick={handleLogout}>
            Logout
          </Menu.Item>
        </SubMenu>
        <SubMenu key="settings" icon={<SettingOutlined/>} title="Settings">
          <Menu.Item key="darkTheme" onClick={props.setDarkTheme}>{props.darkTheme ? "Light" : "Dark"} Mode</Menu.Item>
        </SubMenu>
        <Menu.Item key="users" icon={<SecurityScanOutlined />}  hidden = {rol}>
          <Link to="/users">Users</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
