import { Col, Input, Modal, Row, Form, Button, message, AutoComplete } from "antd";
import React, { useEffect }  from "react";
import { JuvoService } from "../../../services/juvo/juvo.service";

export function UserModalEdit({ visible, onClose, refreshData, CustomerId, rolS ,onClickEdit }) {

  const {  useState  } = React;
  const [form] = Form.useForm();
  const [possibleDevices, setPossibleDevices] = useState([]);
  const [description, setDeviceId] = useState("");

  var des = "";

  const editUser = (e) => {
    refreshDataRole(0);
    console.log(possibleDevices);
 
      possibleDevices.map(d => {
        if(e.roleid === d.description)
        {
            e.roleid = d.id;
            des = d.description;
        }
        
      })

    if(!e.roleid)
    e.roleid = CustomerId.roleid;
      
    JuvoService.updateJuvoUser(
        CustomerId.id,
        {
          email: e.email,
          fullname: e.fullname,
          roleid:e.roleid
        },
        () => {

          message.success("The user has been updated correctly");
          refreshData();
        },
        (e) => {
          message.info("Could not update user, please try again");
        }
      );    

  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const refreshDataRole = (value) => {
    setDeviceId(value);
    JuvoService.ListRole(({ listRole }) => {
        setPossibleDevices(listRole);
      },
      (error) => {
        setPossibleDevices(error.listRole);
      }
    );
  };

  return (
    <div>
      <Modal
        visible={visible}
        title="Edit User "
        footer={null}
        onCancel={onClose}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={editUser}
          onFinishFailed={() => message.error("Ops. An error has ocurred!")}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Fullname"
            name="fullname"
            initialValue={CustomerId.fullname}
            rules={[{ required: true, message: "Please input your fullname!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            initialValue = {CustomerId.email}
            rules={[
              { required: true, message: "Please input your email!" },
            ]}
          >
            <Input maxLength={20} />
          </Form.Item>
          <Form.Item
            label="Role"
            name="roleid"
            initialValue={CustomerId.rolS}
            rules={[
              { required: true, message: "Please select your role!" },
            ]}
          >

               <AutoComplete
          style={{ width: '100%', marginRight: '10px' }}
          onChange={(e) => refreshDataRole(e)}
          value={CustomerId.rolS}
          label ={des}
          options={
            possibleDevices.map(device => { return { id:device.id, value: device.description, label:device.description, key:device.id } })
          }
          placeholder={des}
          filterOption={false}
        />
          </Form.Item>
          <Row justify="end">
            <Col style={{ marginInline: "5px" }}>
              <Button className="btn btn-sm btn-light" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col style={{ marginInline: "5px" }}>
              <Button
                type="primary"
                className="btn btn-sm btn-primary"
                htmlType="submit"
              >
                Edit Customer
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}
