import React from "react";
import { Link } from "react-router-dom";
import { VERSION_NAME } from "../../helpers/enum.helpers";
import Login from "./login.component";
import './loginstyle.css';

export function MainLogin() {
  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>Ohne</Link>
          <div className="version-name-login">{VERSION_NAME}</div>
        </div>
      </nav>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Login />
        </div>
      </div>
    </div>
  );
}