import React, { useEffect } from "react";
import { Modal, Button, message, Row, Col, Table, Space, Switch } from "antd";

import { AutoComplete } from "antd";
import { useState } from "react";
import { JuvoService } from "../../../services/juvo";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

export const MultiplePushNotifications = (props) => {
  const [deviceList, setDeviceList] = useState([]);
  const [possibleDevices, setPossibleDevices] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [messageNotification, setMessageNotification] = useState('')
  const [idBrand, setIdBrand] = useState(122);
  
  const refreshData = (value) => {
    setDeviceId(value);
    JuvoService.getDevicesBySearch(
      value,{ idBrand },null,
      ({ juvoDeviceBySearch }) => {
        setPossibleDevices(juvoDeviceBySearch);
      },
      (error) => {}
    );
  };

  const addDevice = () => {
    let existInDeviceList = false;
    deviceList.forEach(element => existInDeviceList = element.gsfId === deviceId ? true : existInDeviceList);
    if(existInDeviceList)
    {
      message.warning('Ops. The device is already in the list')
      return
    }

    let existId = false;
    possibleDevices.forEach(device => 
    {
      if(deviceId === device.gsfId)
      {
        let newDevices = [
          ...deviceList,
          device,
        ];
        setDeviceList(newDevices);
        existId = true;
      }
    });

    if(!existId){
      message.warning('Ops. Device id does not exist')
    }
  };

  const columns = [
  {
    title: 'Client ID',
    dataIndex: 'gsfId',
    key: 'gsfId',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
  },
  {
    title: 'Model',
    dataIndex: 'model',
    key: 'model ',
  },
  {
    title: 'Action',
    key: 'action',
    render: record => 
      <Space size="middle">
        <Button key={record.gsfId} onClick={() => deleteDevice(record.gsfId)} style={{color:'red'}}>Remove</Button>
      </Space>
    ,
  },]

  const deleteDevice = id => {
    setDeviceList(deviceList.filter(device => device.gsfId !== id))
  }

  const sendNotifications = () => {
    if(messageNotification.length === 0)
    {
      message.warning('Ops. You must enter a message')
      return
    }

    if(deviceList.length === 0)
    {
      message.warning('Ops. You must enter at least one device')
      return
    }
    JuvoService.sendMultiplePush(
      {
        gsfIds: deviceList.map(device => device.gsfId),
        msg: messageNotification,
        idBrand
      }, ( success ) => {
      if(success)
      {
        deviceList.forEach(device => {
          JuvoService.reportJuvoEvent({
            event_id: 5,
            updated_device: parseInt(device.id),
          },() => {}, () => {console.log("Ops, an error has ocurred. Please contact with support")})
        });
        

        message.success('Messages have been sent to the devices successfully')
        props.close();
      }
      else
        message.error('Some messages could not be sent. Contact technical support')
      }, () => {
        message.error('The messages could not be sent. Contact technical support')
      }
    )
  }

  useEffect(() => {
    setDeviceList([]);
    setDeviceId('');
  }, [idBrand]);

  const data = deviceList?.map((item, index) => {
    return {
      ...item,
      key: item?.id,
      action: { item, index },
      phoneNumber: item?.phoneNumber || null,
      gsfId: item?.gsfId || "None",
      brand: item?.brand || "None",
      model: item?.model || "None",
    };
  });

  return (
    <Modal
      title="Send multiple push notifications"
      visible={true}
      footer={
        <Row align='end'>
          <Col span='7'>
            <Button type="button" className="btn btn-primary btn-block btn-sm text-white" onClick={sendNotifications}>Send</Button>
          </Col>
        </Row>
      }
      onCancel={props.close}
    >
      <Row style={{width:'100%', marginBottom:'20px'}}>
        <TextArea type="search" className="form-control form-control-sm" maxLength={100} placeholder="Send Push" value={messageNotification} onChange={e => setMessageNotification(e.target.value)}/>  
      </Row>
      <Row style={{width:'100%', height:'50px'}} gutter={[10,0]} align='middle'>
        <Col flex='auto'>
        <AutoComplete
          style={{ width: '100%', marginRight: '10px' }}
          onChange={(e) => refreshData(e)}
          value={deviceId}
          options={
            possibleDevices.map(device => { return { id:device.gsfId, value: device.gsfId, label:device.gsfId, key:device.gsfId } })
          }
          placeholder="Input device id"
          filterOption={true}
        />
        </Col>
        <Col>
          <Button onClick={addDevice} className="btn btn-light" style={{alignItems:'center', alignContent:'center', display:'flex'}}><PlusOutlined /></Button>
        </Col>
        <Col>
          <Switch checkedChildren="Ohne" unCheckedChildren="Juvo" checked={idBrand === 122} onChange={(e) => setIdBrand(e ? 122 : 123)}/>
        </Col>
        
      </Row>
      <Row style={{width:'100%'}}>
        <Table scroll={{ x:"100%" }} style={{width:'100%'}} dataSource={data} columns={columns} pagination={{pageSize:4}}/>
      </Row>
    </Modal>
  );
};
