import gql from 'graphql-tag';

export const reportJuvoEvent = gql`
  mutation reportJuvoEvent($input: InputJuvoEventHistory!){
    reportJuvoEvent(input:$input){
      id
    }
  }
`

export const juvoDevicesRequestedCall = gql`
  query juvoDevicesRequestedCall{
    juvoDevicesRequestedCall{
      totalCount
      devices{
        id
        phoneNumber
        gsfId
        brand
        model
        status,
        hasSetupPermissions,
        hasDeviceAdminPermission,
        hasOverlayPermission,
        hasUsageStatsPermission,
        waitingResponse
        created_at
        updated_at
      }
    }
  }
`;

export const updateJuvoUser = gql`
  mutation updateJuvoUser($id:ID!, $input: JuvoUserInputEdit!){
    updateJuvoUser(id:$id, input:$input){
      id,
      fullname,
      email,
      active,
      roleid
    }
  }
`;

export const createJuvoUser = gql`
  mutation createJuvoUser($input: JuvoUserInput!){
    createJuvoUser(input: $input){
        id,
      email,
      active,
      password,
      roleid
    }
  }
`;
export const juvoUserbyEmail = gql`
query juvoUserbyEmail($email: String, $change:  Boolean){
  juvoUserbyEmail(email: $email, change: $change){
      id,
    email,
    active,
    fullname,
    password
  }
}
`;

export const signInJuvoUser = gql`
  mutation signInJuvoUser($email: String!, $password: String!){
    signInJuvoUser(email:$email, password:$password){
      token
      user{
        id
        fullname
        email
        roleid
      }
    }
  }
`;

export const sendPushLockUnlock = gql`
  mutation sendPushLockUnlock($gsfId: String!, $idBrand: Int!, $isLock:Boolean!){
    sendPushLockUnlock(gsfId:$gsfId, idBrand:$idBrand, isLock:$isLock) {
      id
      gsfId
      androidVersion
      brand
      model
      buildNumber
      tag
      pin
      status
      idBrand
      hasDeviceAdminPermission
      hasOverlayPermission
      hasUsageStatsPermission
      waitingResponse
      created_at
      updated_at
      customerId
    }
  }
`;

export const juvoDeviceBySearch = gql`
  query juvoDeviceBySearch($gsfId: String, $fields: JSON, $start:Int, $limit:Int, $risky: Boolean){
    juvoDeviceBySearch(gsfId:$gsfId, fields:$fields, start:$start, limit:$limit, risky:$risky){
      id
      gsfId
      androidVersion
      brand
      model
      buildNumber
      tag
      pin
      idBrand
      status
      hasSetupPermissions
      hasDeviceAdminPermission
      hasOverlayPermission
      hasUsageStatsPermission
      waitingResponse
      created_at
      updated_at
      phoneNumber

      customerFullname
      customerId
      customerPhoneNumber
      CustomerStatus
    }
  }
`;

export const juvoDevices = gql`
  query juvoDevices($start:Int, $limit:Int, $fields: JSON){
    juvoDevices(start:$start, limit:$limit, fields: $fields){
      totalCount
      devices{
        id
        phoneNumber
        gsfId
        brand
        model
        status,
        hasSetupPermissions,
        hasDeviceAdminPermission,
        hasOverlayPermission,
        hasUsageStatsPermission,
        waitingResponse
        created_at
        updated_at
        customerId
      }
    }
  }
`;

export const juvoDevice = gql`
  query juvoDevice($id:ID!){
    juvoDevice(id:$id){
      id
      gsfId
      androidVersion
      brand
      model
      buildNumber
      tag
      pin
      status
      idBrand
      hasSetupPermissions
      hasDeviceAdminPermission
      hasOverlayPermission
      hasUsageStatsPermission
      waitingResponse
      created_at
      updated_at
      customerId
      isRegistered
    }
  }
`;

export const juvoUser = gql`
  query juvoUser($id:ID!){
    juvoUser(id:$id){
      id
      fullname
      email
    }
  }
`;

export const juvoDeviceStats = gql`
  query juvoDeviceStats($risky:Boolean){
    juvoDeviceStats(risky:$risky){
      riskyDevices
      totalDevices
      requestedCallDevices
      lockedDevices
      unlockedDevices
      hasSetupPermissions
    }
  }
`;

export const devicebyOwner = gql`
query devicebyOwner($id:Int) {
  devicebyOwner(id:$id){
    model
    gsfId
    brand
    phoneNumber
    id
    tag
    hasDeviceAdminPermission
    hasOverlayPermission
    hasUsageStatsPermission,
    hasSetupPermissions
 }
}
`;

export const juvoCustomers = gql`
  query juvoCustomers($start:Int, $limit:Int, $search: String){
    juvoCustomers(start:$start, limit:$limit, search: $search){
      customers{
        id
        created_by
        fullname
        created_at
        phonenumber
      }
      totalCount
    }
  }
    `;

export const findCustomers = gql`
query findCustomers($id:ID,$fullname:String, $phonenumber: String, $start:Int, $limit:Int){
  findCustomers(id:$id, fullname:$fullname, phonenumber: $phonenumber, start:$start, limit:$limit){
    id
    created_by
    fullname
    created_at
    phonenumber
  }
}
  `;

  
  export const JuvoUserBySearch = gql`
  query JuvoUserBySearch($search:String,$start: Int, $limit:Int) {  
    JuvoUserBySearch(search:$search,start: $start, limit: $limit) {    
      users{
        id
        fullname
        active
        email
        created_at
        updated_at,
        roleid
      },
      totalCount
       }}
    `;

  export const juvoUsers = gql`
  query juvoUsers($start: Int, $limit:Int) {  
    juvoUsers(start: $start, limit: $limit) {    
      users{
        id
        fullname
        active
        email
        created_at
        updated_at,
        roleid
      },
      totalCount
       }}
    `;

    export const listRole = gql`
    query listRole($id:Int, $start: Int, $limit:Int) {  
      listRole(id:$id,start: $start, limit: $limit) {    
        id
        description
         }
         }
      `;

export const sendMultiplePush = gql`
  mutation sendMultiplePush($gsfIds: [String]!, $msg:String!, $idBrand: Int!){
    sendMultiplePush(gsfIds:$gsfIds,msg:$msg, idBrand:$idBrand)
  }
`;

export const sendPush = gql`
  mutation sendPush($gsfId: String!, $idBrand: Int!, $msg:String!){
    sendPush(gsfId:$gsfId, idBrand:$idBrand, msg:$msg) {
      id
      gsfId
      androidVersion
      brand
      model
      buildNumber
      tag
      pin
      idBrand
      status
      hasSetupPermissions
      hasDeviceAdminPermission
      hasOverlayPermission
      hasUsageStatsPermission
      waitingResponse
      created_at
      updated_at
    }
  }
`;

export const generatePin = gql`
  mutation generatePin($gsfId: String){
    generatePin(gsfId:$gsfId) {
      id
      gsfId
      androidVersion
      brand
      model
      buildNumber
      tag
      pin
      status
      hasSetupPermissions
      hasDeviceAdminPermission
      hasOverlayPermission
      hasUsageStatsPermission
      waitingResponse
      created_at
      updated_at
    }
  }
`;

export const juvoNotificationsByUser = gql`
  query juvoNotificationsByUser($id_user:ID!){
    juvoNotificationsByUser(id_user:$id_user){
      notifications{
        id
        id_event_history
        viewed
        status
        event_id
        id_device
        gsfId
        brand
        model
        waitingResponse
        updated_at
      }
      totalCount
    }
  }
`

export const markAsSeenNotification = gql`
  mutation markAsSeenJuvoNotification($id:ID!){
    markAsSeenJuvoNotification(id:$id)  
  }
`

export const markAsSeenAllJuvoNotification = gql`
  mutation markAsSeenAllJuvoNotification($userId:ID!){
    markAsSeenAllJuvoNotification(userId:$userId)  
  }
`

export const juvoDevicesWithCustomers = gql`
  query juvoDevicesWithCustomers($start:Int, $limit: Int, $fields: JSON, $risky: Boolean){
    juvoDevicesWithCustomers(start:$start, limit:$limit, fields:$fields, risky:$risky)
    {
      devices {
        id
        gsfId
        androidVersion
        brand
        model
        buildNumber
        tag
        pin
        status
        hasSetupPermissions
        hasDeviceAdminPermission
        hasOverlayPermission
        hasUsageStatsPermission
        waitingResponse
        created_at
        updated_at
        phoneNumber
        isRegistered
        idBrand

        customerFullname
        customerId
        customerPhoneNumber
        CustomerStatus
      }
      totalCount
    }
  }
`

export const createJuvoCustomer = gql`
  mutation createJuvoCustomer($input: InputJuvoCustomer!){
    createJuvoCustomer(input: $input){
      id,
      fullname,
      phonenumber
    }
  }
`;


export const updateJuvoCustomer = gql`
  mutation updateJuvoCustomer($id: ID!, $input: JuvoCustomerInputEdit!){
    updateJuvoCustomer(id:$id, input:$input){
      id
    }
  }
`

export const updateJuvoData = gql`
  mutation updateJuvoData($id: ID!, $input: InputJuvoDevice!){
    updateJuvoData(id:$id, input:$input){
      id
    }
  }
`

export const juvoCustomerBySearch = gql`
  query juvoCustomerBySearch($fullname: String, $start: Int, $limit: Int, $fields:JSON){
    juvoCustomerBySearch(fullname:$fullname, start:$start, limit:$limit, fields:$fields){
      id
      created_by
      fullname
      created_at
      phonenumber
    }
  }
`

export const juvoDashboardsData = gql`
  query juvoDashboardsData($dateRange:[JSON], $risky: Boolean){
    juvoDashboardsData(dateRange:$dateRange, risky:$risky){
      newDevicesByDate{
        date
        count
      }
      totalDevicesByDate{
        date
        count
      }
      dateFormat
    }
  }
`

export const juvoEventHistory = gql`
  query juvoEventHistory($start:Int, $limit:Int, $startDate: String, $endDate: String, $search: String, $fields:JSON){
    juvoEventHistory(start:$start, limit:$limit, startDate:$startDate, endDate:$endDate, search:$search, fields:$fields){
      events{
        id
        gsfId
        event_id
        event_name
        updated_device
        created_by
        created_at
        user_fullname
        user_email
        customer_fullname
        customer_id
      }
      totalCount
    }
  }
`

export const juvoEventTypes = gql`
  query juvoEventTypes($start:Int, $limit:Int){
    juvoEventTypes(start:$start, limit:$limit){
      id
      event_name
      description
    }
  }
`
// Query for the methods in graphql

export const sendPushToForceUpdateTokenOhne = gql`
mutation sendPushToForceUpdateTokenOhne{
  sendPushToForceUpdateToken(topic:"1.0.10-10-122-DEV"){
  message
  statusCode
  }
}
`
export const sendPushToForceUpdateTokenJuvo = gql`
mutation sendPushToForceUpdateTokenJuvo{
  sendPushToForceUpdateToken(topic:"1.0.10-10-123-DEV"){
  message
  statusCode
  }
}
`
