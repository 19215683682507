import { makeStyles } from '@material-ui/core/styles';

export const deviceStyle = makeStyles(() => ({
  containerItems: {
    display: 'flex',
    width: '100%',
    '& > div ': {
      display: 'flex',
      width: '50%',
      flexWrap: 'wrap',
      margin: '5px 5px',
    },
  },
  containerItemAction: {
    display: 'flex',
    width: '100%',
    '& > div ': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flexWrap: 'wrap',
      margin: '5px 10px',
    },
  },
  containerAction: {
    display: 'flex',
    flexDirection: 'row',
    '& > button': {
      marginLeft: 30,
      height: 'fit-content',
      width: 'auto',
    },
  },
  containerTags: {
    flexWrap: 'wrap',
    '& > span': {
    },
  },
  textBold: {
    display: 'flex',
    fontWeight: 700,
    fontSize: '13px',
    fontFamily: 'system-ui',
    overflowWrap: 'anywhere',
    color: '#00B678',
  }
}));
