import React from "react";
import { Button, Row, Table, Space, message, Col } from "antd";
import { useState } from "react";
import { JuvoService } from "../../../services/juvo";
import { EditOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { LocalStorageKeys } from "../../../helpers";
import ServiceHelper from "../../../services/serviceHelper";

export default function CustomerDevice({ refreshData, customerId }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: undefined,
    total: undefined,
    pageSize: undefined,
  });
  const [deviceListSettings, setDeviceListSettings] = useState({
    start: 0,
    limit: 4,
    fields: { customerId: null },
  });

  useEffect(() => {
    JuvoService.getDevices(deviceListSettings, ({ devices, totalCount }) => {
      setDeviceList(devices);
      setTotalDevices(totalCount);
      setPagination({ current: 1, total: totalCount, pageSize: 4 });
    });
  }, []);

  useEffect(() => {
    // JuvoService.getDevices(deviceListSettings, ({ devices, totalCount }) => {
    //   setDeviceList(devices);
    //   setTotalDevices(totalCount);
    // });
  }, [pagination]);

  const columns = [
    {
      title: "Client ID",
      dataIndex: "gsfId",
      key: "gsfId",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model ",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <Button
            key={record.id}
            onClick={() => assignDevice(record.id)}
            className="btn  btn-sm btn-primary"
            style={{ color: "white", alignItems: "center", display: "flex" }}
          >
            Assign device
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const assignDevice = (deviceId) => {
    setIsLoading(true);
    JuvoService.updateJuvoDevice(
      { id: deviceId, input: { customerId: parseInt(customerId) } },
      () => {
        message.success("Has been assigned successfully");
        refreshData();
        JuvoService.updateJuvoDevice({id: deviceId, input: { isRegistered: true }})
        JuvoService.getDevices(
          { start: 0, limit: 100, fields: { customerId: null } },
          ({ devices, totalCount }) => {
            setDeviceList(devices);
            setTotalDevices(totalCount);
            setIsLoading(false);
          }, () => {
            setIsLoading(false);
          }
        );
      },
      (error) => {
        message.error("Could not assign, contact technical support");
        setIsLoading(false);
      }
    );
  };

  const handleTableChange = (p) => {
    setIsLoading(true)
    setPagination({
      current: p.current,
      total: pagination.total,
      pageSize: pagination.pageSize,
    });
    const newSettings = {
      start: p.pageSize * (p.current - 1),
      limit: p.pageSize,
      fields: deviceListSettings.fields,
    };
    setDeviceListSettings(newSettings);
    JuvoService.getDevices(newSettings, ({ devices, totalCount }) => {
      setDeviceList(devices);
      setTotalDevices(totalCount);
      setIsLoading(false)
    });
  };

  const [searchText, setSearchText] = useState('');
  const onClickSearch = () => {
    setIsLoading(true);
    JuvoService.getDevicesBySearch(searchText, deviceListSettings.fields, null, ({ juvoDeviceBySearch }) => {
      setTotalDevices(juvoDeviceBySearch.length);
      setDeviceList(juvoDeviceBySearch);
      setPagination({ current: 1, total: juvoDeviceBySearch.length, pageSize: 4 });
      setIsLoading(false);
    }, (e) => {
      setIsLoading(false)
      message.error(ServiceHelper.getErrorMessageFromGraphql(e) || 'Ops. An error has ocurred!');
    })
  };

  const handleChangePassword = (e) => {
    const search = e.target.value;
    setSearchText(search);
  };

  const onClickClear = () => {
    const newPagination = { ...pagination };
    setPagination(newPagination)
    setSearchText('')
    JuvoService.getDevices(deviceListSettings, ({ devices, totalCount }) => {
      setDeviceList(devices);
      setTotalDevices(totalCount);
      setPagination({ current: 1, total: totalCount, pageSize: 4 });
    });
  }

  return (
    <div>
      <Row>
        <Col style={{ marginBottom: "20px" }}>
          <input
            type="search"
            className="form-control form-control-sm"
            placeholder="Find by client id"
            onChange={handleChangePassword}
            value={searchText}
          />
        </Col>
        <Col style={{ marginInlineStart: "20px", marginBottom: "20px" }}>
          <button
            type="button"
            className="btn btn-primary btn-block btn-sm"
            onClick={onClickSearch}
          >
            Search
          </button>
        </Col>
        <Col style={{ marginInlineStart: "20px", marginBottom: "20px" }}>
          <button
            type="button"
            className="btn btn-secondary btn-block btn-sm"
            onClick={onClickClear}
          >
            Clear
          </button>
        </Col>
      </Row>
      <Row style={{ width: "100%" }}>
        <Table
          rowKey='id'
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: "100%" }}
          style={{ width: "100%" }}
          dataSource={deviceList}
          columns={columns}
          pagination={pagination}
        />
      </Row>
    </div>
  );
}
