import ApolloClient from 'apollo-boost';

export class ConnectionService {
  static getGraphqClient() {
    return new ApolloClient({
      uri: process.env.REACT_APP_URL_SERVER || '',
    });
  }

  static getGraphqClientWithJWT(token) {
    return new ApolloClient({
      uri: process.env.REACT_APP_URL_SERVER || '',
      headers: {
        authorization: `bearer ${token}`,
      },
    });
  }
}
