import { Button, message, Modal, Row, Col, Table, Input } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import React, { useCallback, useEffect, useState } from "react";
import { JuvoService } from "../../../services/juvo";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";

export default function CustomerAssignment({ deviceId, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
  });
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: ({ item, index }) => {
        return (
          <ButtonGroup
            variant="outlined"
            color="default"
            size="small"
            className="btn_action_device"
          >
            <Button
              title= "Assign device to this customer"
              onClick={() =>{ assignCustomer(item.id); }}
            >
              <LinkOutlined/>
            </Button>
          </ButtonGroup>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      align: "center",
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",  
    },
  ];

  useEffect(() => {
    if(!deviceId)
    {
      message.error("Device ID is required");
      onClose();
    }
    getCustomers();
  },[pagination]);
  

  const handleTableChange = p => {
    setPagination({
      ...pagination,
      current: p.current,
      pageSize: p.pageSize
    });
  };

  const assignCustomer = (customerId) => {
    Modal.confirm({
      title: "Are you sure to assign this customer?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        setIsLoading(true);
        JuvoService.updateJuvoDevice({ id: deviceId, input: { customerId, isRegistered: true }}, 
          () => {
            message.success("Device has been assigned to customer");
            onClose();
          },
          () => {
            message.error("Device has not been assigned to customer");
            setIsLoading(false);
          });
      },
    });
  };

  const getCustomers = useCallback(() => {
    setIsLoading(true);
    const params = {
      start: pagination.pageSize * (pagination.current - 1),
      limit: pagination.pageSize,
      search: search
    }

    JuvoService.getCustomers(params, ({ juvoCustomers }) => {
      setCustomerList(juvoCustomers.customers);
      if(pagination.total === 0)
      {
        setPagination({
          ...pagination,
          total: juvoCustomers.totalCount
       });
      }
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);
      message.error("Ops. An error has ocurred!");
    });
  }, [pagination, search]);

  const data = customerList.map((item, index) => {
    return {
      key: item?.id,
      action: { item, index },
      phoneNumber: item?.phonenumber || "None",
      fullname: item?.fullname || "None",
      created_at: item?.created_at
        ? moment(new Date(parseFloat(item?.created_at))).format(
            "M/D/Y hh:mma"
          )
        : "None",
    };
  });

  return (
    <Modal
      title="Assign customer to device"
      centered
      visible={true}
      onCancel={onClose}
      footer={null}
    >
      <Row gutter={[20,0]}>
        <Col flex='auto'>
          <Input placeholder='Search by name or phonenumber' value={search} onChange={(e) => setSearch(e.target.value)}/>
        </Col>
        <Col>
          <button 
            className='btn btn-sm btn-primary'
            onClick={() => getCustomers()}
          >Search</button>
        </Col>
      </Row>
      <Row justify='center'>
        <Table
          loading={isLoading}
          dataSource={data}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Row>      
    </Modal>
  );
}
