import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Logo from '../../assets/juvo_logo.png';
import ButtonLoading from "../../components/buttonloading.component";
import { LocalStorageKeys } from "../../helpers";
import { JuvoService } from '../../services/juvo'
import ServiceHelper from '../../services/serviceHelper'
import { Button } from 'antd';
import { Link } from "react-router-dom";

export default function Login() {

  const history = useHistory();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [roleid, setRolId] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const login = () => {
    setLoading(true);
    const userData = { email: user, password, roleid }
    JuvoService.signIn(userData,
      ({ token, user: userData }) => {
        setUser(user);
        setPassword(password);
        setRolId(userData.roleid);
        setIsError(false);
        localStorage.setItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN, token);
        localStorage.setItem(LocalStorageKeys.KEY_SAVE_USER_DATA, JSON.stringify(userData));
        history.push('/dashboard');
      },
      (error) => {
        const message = ServiceHelper.getErrorMessageFromGraphql(error)
        setErrorMessage(message || 'Ops an error has ocurred');
        setIsError(true);
        setLoading(false);
      });
  };

  const handleChangeUser = (e) => {
    const email = e.target.value;
    setUser(email);
  };

  const handleChangePassword = (e) => {
    const pass = e.target.value;
    setPassword(pass);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  };

  useEffect(() => {
    if (LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN !== null) {
      localStorage.removeItem(LocalStorageKeys.KEY_SAVE_LOGIN_TOKEN);
      localStorage.removeItem(LocalStorageKeys.KEY_SAVE_USER_DATA);
    }
  }, []);

  return (
    <div onSubmit={(e) => { e.preventDefault(); }}>
      <div className="imageLogin">
        <img src={Logo} alt="ECFone" />
      </div>
      <h3>Sign In</h3>
      <div className="form-group">
        <label>Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          onKeyDown={keyPress}
          onChange={handleChangeUser}
          value={user}
        />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          onKeyDown={keyPress}
          onChange={handleChangePassword}
          value={password}
        />
      </div>

      <div className="form-group text-center">
        {isError && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
      <ButtonLoading
        loading={loading}
        onClick={login}
      >
        Login
      </ButtonLoading>
      <div className="form-group text-center">
        <Link type="link" to="/recoverypassword" style={{ textAlign: 'center', fontWeight: "bolder" }} success>
          Forgot my password
        </Link>
      </div>
    </div>
  );
}
